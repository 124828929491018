import React from 'react';
import "./css/Home.css";
import NewspaperIcon from '@mui/icons-material/Newspaper';

import GptApi from './components/GptApi.jsx';


function Weather() {

  


  return (
    
    <div>
      <h3><NewspaperIcon/>&nbsp;World Info</h3>
    <GptApi/>

    </div>
  );
}

export default Weather;