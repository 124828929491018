import React, { useState, useEffect } from 'react'

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import CancelIcon from '@mui/icons-material/Cancel';

import axios from 'axios';
import { api_url } from './FetchMingfeiApi'

import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

// import Modal from 'react-bootstrap/Modal'

import { Typography } from '@mui/material';
import useClasses from '../UseClass';

const local_url = process.env.LOCAL_URL || 'http://localhost:3000';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    myText: {
        color: 'green',
        display:"inline-block"
      },   
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(2),

        width: '25ch',
      },
    button:{
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 8
    },
    p: { color: 'green',
    display:"inline-block"        
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: 600,
        margin: theme.spacing(3 ),
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
      },
  });


const apiKey="b57c38d11emsh32976aeb83f05afp113107jsn102d78a776e1";
const apiHost="yh-finance.p.rapidapi.com";

const Shirt = ({shirt}) => {
    const classes = useClasses(styles);
    const [message, setMessage] = useState(null);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [valide, setValide] = useState('');
    const [show, setShow]=useState(false)
    const [price, setPrice] = useState(null);
    const [priceChangePerc, setPriceChangePerc] = useState(null);

    
    const options = {
        method: "GET",
        url: "https://yh-finance.p.rapidapi.com/stock/v2/get-summary",
        params: { symbol: shirt.Brand, region: "US" },
        headers: {
          "x-rapidapi-key": apiKey,
          "x-rapidapi-host": apiHost,
        },
      };
    
      
    
  
    function turnReadOnly(e) {
        e.preventDefault();
        isReadOnly? setIsReadOnly(false):setIsReadOnly(true)
        isReadOnly? console.log('The link was Editable') : handleUpdate(
            api_url,
            shirt._id, 
            document.getElementById('cx-rating').value, 
            document.getElementById('title-input').value, 
            document.getElementById('review-input').value) 

      }

    function handleUpdate(url, id, myRating, myTitle, myReview){
        const id_api_url=url+'\\'+id
        const Rating=myRating
        const comment_name=myReview
        const Description=myTitle
        setMessage("Saved!")
        // console.log(id_api_url+' update '+myTitle+'--'+comment_name)

        axios.put(id_api_url, { Rating, comment_name,Description })
        .then(res => {
            console.log(res);
            console.log(res.data);
        })
        .catch(error => {
            console.log(error.response)
        })
    }

    function handleDeleteX(event,target_value, id){
        var my_delete = document.getElementById('item_delete_confirm').value;
        if (my_delete===target_value)
        {
            event.preventDefault();
            console.log(my_delete)
            axios.delete(api_url+`/${id}`)
            .then(res => {
              console.log(res.data);
              window.location = local_url+"/review";
              
            })
            .catch(error => {
              console.log(error.response)
          });

    
        }
      else {
            setValide("Incorrect Input")
        }      
      };  


    const handleOpen = () => {
        setShow(true);
      };

    const handleClose = () => {
        setShow(false);
      };

    const fetchStockPrice = async () => {
        await axios.request(options)
        .then(function (response) {
            setPrice(response.data.price.regularMarketPrice.raw);

            let old_price=Number(shirt.Description.split(' at $')[1])
            if (old_price>0) {
                setPriceChangePerc(Math.round(((response.data.price.regularMarketPrice.raw/old_price)-1)*100))
            }
            
        })
    }
    fetchStockPrice()

    useEffect(() => {
        document.title = `BrandWatcher ${isReadOnly}`;
        
    });

    if (isReadOnly) {
        return (
            <form className={classes.root} noValidate autoComplete="off">
                
            <div>
            
                <TurnedInNotIcon color="primary" style={{ margin: 10, display:"inline-block" }}/>
                <br></br>
                <TextField
                id="standard-read-only-input"
                label="User"
                defaultValue={shirt.user_name}
                InputProps={{
                    readOnly: true,
                }}
                className={classes.textField}
                />
                <TextField
                id="standard-read-only-input"
                label="Brand"
                defaultValue={shirt.Brand}
                InputProps={{
                    readOnly: true,
                }}
                className={classes.textField}
                />

                

                <TextField
                id="cx-rating" 
                label="Rating(0~10)"  
                type="number" 
                style = {{width: 100}}
                InputProps={{
                    readOnly: true,
                    
                }}
                defaultValue={shirt.Rating}
                className={classes.textField}
                />  

                <TextField
                id="title-input"
                label="Title"
                style={{ margin: 8 }}
                defaultValue={shirt.Description}
                placeholder="Your Title"
                helperText="The Subject of Review"
                fullWidth
                // margin="normal"
                InputLabelProps={{
                shrink: true,
                }}
                InputProps={{
                    readOnly: true,
                }}
                />
                <TextField
                id="review-input"
                label="Review"
                defaultValue={shirt.comment_name}
                multiline
                fullWidth
                style={{ margin: 8 }}
    
                InputProps={{
                    readOnly: true,
                }}
                />
                <Typography className={classes.textField}>Current: ${price}</Typography> 

                {priceChangePerc > 0 ? (
                <p style={{ marginTop: "1em", color: "rgba(6, 201, 42,0.9)" }}>
                <ArrowUpwardIcon />
                +{priceChangePerc}%
                </p>
                ) : priceChangePerc < 0 ? (
                <p style={{ marginTop: "1em", color: "rgba(227, 59, 109,0.9)" }}>
                <ArrowDownwardIcon />
                {priceChangePerc}%
                </p>
                ) : (
                <div></div>
                )}
               
              
                <Button className={classes.button}
                variant="outlined" href={`${local_url}/review`}>Return</Button>
                <Button className={classes.button}
                onClick={turnReadOnly}
                variant="contained">
                Edit
                </Button>
                <LockIcon style={{ margin: 10, display:"inline-block" }} />
                <p className={classes.myText}>{message}</p>
            </div>
            </form>
        )
    }
    return (
        <form className={classes.root} noValidate autoComplete="off">
            
        <div>
        <TurnedInNotIcon color="primary" style={{ margin: 10, display:"inline-block" }}/>
        <br></br>
            
            <TextField
            id="standard-read-only-input"
            label="User"
            defaultValue={shirt.user_name}
            InputProps={{
                readOnly: true,
            }}
            className={classes.textField}
            />
            <TextField
            id="standard-read-only-input"
            label="Brand"
            defaultValue={shirt.Brand}
            InputProps={{
                readOnly: true,
            }}
            className={classes.textField}
            />

                     
            
            <TextField
                id="cx-rating" 
                label="Rating(0~10)"  
                type="number" 
                style = {{width: 100}}
                InputProps={{
                    readOnly: false,
                    max: 10, min: 0 
                }}
                defaultValue={shirt.Rating}
                className={classes.textField}
            />
            <TextField
            id="title-input"
            label="Title"
            style={{ margin: 8 }}
            defaultValue={shirt.Description}
            placeholder="Your Title"
            helperText="The Subject of Review"
            fullWidth
            // margin="normal"
            InputLabelProps={{
            shrink: true,
            }}
            InputProps={{
                readOnly: false,
                  
            }}
            />

            

            <TextField
            id="review-input"
            label="Review"
            defaultValue={shirt.comment_name}
            multiline
            fullWidth
            style={{ margin: 8 }}

            InputProps={{
                readOnly: false,
            }}
            />
            <Typography className={classes.textField}>Current: ${price}</Typography>
            {priceChangePerc > 0 ? (
                <p style={{ marginTop: "1em", color: "rgba(6, 201, 42,0.9)" }}>
                <ArrowUpwardIcon />
                +{priceChangePerc}%
                </p>
                ) : priceChangePerc < 0 ? (
                <p style={{ marginTop: "1em", color: "rgba(227, 59, 109,0.9)" }}>
                <ArrowDownwardIcon /> 
                {priceChangePerc}%
                </p>
                ) : (
                <div></div>
                )}
            <Button className={classes.button}
            variant="outlined" href={`${local_url}/comment`}>Return</Button>
            <Button className={classes.button}
            onClick={turnReadOnly}
            variant="contained" color="primary">
            Save
            </Button>
            <BorderColorIcon color="primary" style={{ margin: 10, display:"inline-block" }} />
            <p className={classes.myText}>{message}</p>

            <Tooltip title="Delete">
            <IconButton aria-label="delete" color="secondary" onClick={handleOpen}> 
            <DeleteIcon />
            </IconButton>
            </Tooltip>

        </div>

            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={show}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
            >
            <Fade in={show}>
            <div className={classes.paper}>
            <TextField id="item_delete_confirm" label={`Type ${shirt.Brand} to confirm`} variant="outlined" fullWidth/>
            {valide==="" ? ( console.log("nothing")) : (<div className="alert alert-danger" role="alert">{valide}</div>)}


        <Button
        variant="contained"
        className={classes.button}
        startIcon={<CancelIcon />}
        onClick={handleClose}
        >
        Cancel
        </Button>

        <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<DeleteIcon />}
        onClick={(event) => handleDeleteX(event, shirt.Brand, shirt._id)}
        >
        Delete
        </Button>

        </div>
        </Fade>
        </Modal>
        </form>





    )
}

export default Shirt










