import React from 'react';
import FetchMingfeiApi from "./components/FetchMingfeiApi.jsx";
import "./css/Home.css";


function Review() {

  


  return (
    
    <div>
    <FetchMingfeiApi/>

    </div>
  );
}

export default Review;