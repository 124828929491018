import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import "../css/Home.css";
import Box from '@mui/material/Box';

import Divider from '@mui/material/Divider';
import { Bar, Pie } from 'react-chartjs-2';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

// https://news-api-service-dot-mingfei-test-account.appspot.com

// http://localhost:5000/scrape

export default class GptApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      word_count: '',
      verb_count: '',
      extracted_words_count: '',
      sentiment_count: '',
      loading: false,
      // started: false,
      text: '',
      test: 0,
      error: null,
      selectedCountry: '',
      currentDate: new Date(),
      refreshKey: 0

    };
    this.interval = null;
  }

  handleChange = (event) => {
    this.setState({ selectedCountry: event.target.value });
  };

  handleScrape = async () => {
    this.setState({ loading: true, text: '' });


    try {
      //
      const response = await fetch('https://news-api-service-dot-mingfei-test-account.appspot.com/scrape', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ country: this.state.selectedCountry })
      });
      //


      const data = await response.json();
      this.setState({
        // my_text: data.text,
        text: data.text,
        word_count: data.word_count,
        verb_count: data.verb_count,
        extracted_words_count: data.extracted_words_count,
        sentiment_count: data.sentiment_count,
        loading: false,
        error: null,
        // started: false
      });

      console.log("sentiment_count: ", data.sentiment_count)
      // if (this.state.started) return; // Prevent multiple starts
      // this.setState({ started: true, });

      // this.setState(prevState => ({
      //   refreshKey: prevState.refreshKey + 1
      // }));
      // let index = 0;
      // this.interval = setInterval(() => {
      //   this.setState(prevState => ({
      //     text: prevState.text + this.state.my_text[index - 1],

      //   }));
      //   index++;
      //   if (index === this.state.my_text.length) {
      //     clearInterval(this.interval);
      //   }
      // }, 40); // Adjust the speed by changing this value
      // this.setState({ started: false, });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    };

  }



  componentWillUnmount() {
    clearInterval(this.interval);
  }


  render() {
    const { currentDate, text, verb_count, word_count, loading, error, extracted_words_count, sentiment_count } = this.state;
    // const formattedText = text.split(/[\.\?\|\!\！\？]/).map((segment, index) => (
    //   <span key={index}>
    //     {segment.trim()}
    //     {segment && <><br /><br /></>}
    //   </span>
    // ));

    // Sort the dictionary by value
    const verb_sortedEntries = Object.entries(verb_count).sort((a, b) => b[1] - a[1]);
    const sortedEntries = Object.entries(word_count).sort((a, b) => b[1] - a[1]);
    const word_sortedEntries = Object.entries(extracted_words_count).sort((a, b) => b[1] - a[1]);
    const sentimental_sortedEntries = Object.entries(sentiment_count).sort((a, b) => b[0] - a[0]);

    // Extract labels and data from the sorted dictionary
    const sentimental_labels = sentimental_sortedEntries.map(entry => entry[0]);
    const sentimental_data = sentimental_sortedEntries.map(entry => entry[1]);
    // Chart configuration
    const sentimental_chartData = {
      labels: sentimental_labels,
      datasets: [{
        label: 'Count By Sentiment',
        data: sentimental_data,
        backgroundColor: ['#64C2A6','#FEAE65', '#F66D44','#AADEA7','#E6F69D'],
        borderColor: ['#64C2A6', '#FEAE65', '#F66D44', '#AADEA7','#E6F69D'],
        borderWidth: 1
      }]
    };


    // Extract labels and data from the sorted dictionary
    const counter_labels = word_sortedEntries.map(entry => entry[0]);
    const counter_data = word_sortedEntries.map(entry => entry[1]);
    // Chart configuration
    const counter_chartData = {
      labels: counter_labels,
      datasets: [{
        label: 'Count By Source',
        data: counter_data,
        backgroundColor: '#e28743',
        borderColor: '#eab676',
        borderWidth: 1
      }]
    };

    // Extract labels and data from the sorted dictionary
    const verb_labels = verb_sortedEntries.map(entry => entry[0]);
    const verb_data = verb_sortedEntries.map(entry => entry[1]);
    // Chart configuration
    const verb_chartData = {
      labels: verb_labels,
      datasets: [{
        label: 'Frequency',
        data: verb_data,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    };



    // Extract labels and data from the sorted dictionary
    const labels = sortedEntries.map(entry => entry[0]);
    const data = sortedEntries.map(entry => entry[1]);
    // Chart configuration
    const chartData = {
      labels: labels,
      datasets: [{
        label: 'Frequency',
        data: data,
        backgroundColor: '#f6d860',
        borderColor: '#F7c919',
        borderWidth: 1
      }]
    };

    const formattedDate = currentDate.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).split('/').reverse().join('-');

    return (
      <div>
        <h4>{formattedDate}</h4>
        <Box sx={{ m: 2 }}> <FormControl variant="outlined" fullWidth>
          <InputLabel id="country-label">Domain</InputLabel>
          <Select
            labelId="country-label"
            id="country-select"
            value={this.state.country}
            onChange={this.handleChange}
            label="Country"
          >
            <MenuItem value={"USA"}>USA</MenuItem>
            <MenuItem value={"France"}>France</MenuItem>
            <MenuItem value={"UK"}>United Kingdom</MenuItem>
            <MenuItem value={"Canada"}>Canada</MenuItem>
            <MenuItem value={"Quebec"}>Quebec</MenuItem>
            <MenuItem value={"Spain"}>Spain</MenuItem>
            <MenuItem value={"China"}>China</MenuItem>
            <MenuItem value={"Finance"}>Finance</MenuItem>


          </Select>
        </FormControl></Box>


        <Button variant="contained" color="primary" style={{ margin: '16px' }} onClick={this.handleScrape} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Scan'}
        </Button>

        <Divider variant="middle" />
        <div>
          {loading ? '' : <Bar
            data={chartData}
            options={{
              title: {
                display: true,
                text: 'Top Topics',
                fontSize: 20
              },
              legend: {
                display: true,
                position: 'top'
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              }
            }}
          />}
        </div>

        <div>
          {loading ? '' : <Bar
            data={verb_chartData}
            options={{
              title: {
                display: true,
                text: 'Top Verbs',
                fontSize: 20
              },
              legend: {
                display: true,
                position: 'top'
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              }
            }}
          />}
        </div>


        <div>
          {loading ? '' : <Bar
            data={counter_chartData}
            options={{
              title: {
                display: true,
                text: 'Top Word Count by Source',
                fontSize: 20
              },
              legend: {
                display: true,
                position: 'top'
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              }
            }}
          />}
        </div>
        <div>{loading ? '' : <Pie
          data={sentimental_chartData}
          options={{
            title: {
              display: true,
              text: 'Sentiment Analysis',
              fontSize: 20
            },
            legend: {
              display: true,
              position: 'top'
            }
          }}
        />
        }</div>



        <Box key={this.state.refreshKey} sx={{ m: 2 }}>

          {error ? <div>{this.state.error}</div> : <div className="typing" dangerouslySetInnerHTML={{ __html: text }}></div>}

        </Box>

      </div>

    );
  }
}
