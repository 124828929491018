import React from 'react';

import useClasses from '../UseClass';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const styles = theme =>({
  root: {
    width: "100%",
  },

  footer:{
    marginTop:30,
    alignItems: 'center',

  },
  tag:{
    fontFamily:"Roboto"
  }
});




export default function LabelBottomNavigation() {
  const classes = useClasses(styles);
  var currentY=new Date().getFullYear()

  return (
    <AppBar position="static" color="transparent" className={classes.footer}>
      <Toolbar>
        <Typography variant="body1" color="inherit" >
          © {currentY} by <span className={classes.tag}>Mingfei</span> Atelier
        </Typography>
      </Toolbar>
  </AppBar>
  );
}
