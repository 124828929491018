import React from 'react';
import { createRoot } from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';
import './polyfills';
import App from './App';
import './css/index.css';
import serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
/* eslint-disable react/jsx-filename-extension */

const root = createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorker();
