import React, { useEffect, useState } from "react";
import useClasses from './UseClass'
import { useOktaAuth } from '@okta/okta-react';
// import FetchMingfeiApi from "./components/FetchMingfeiApi.jsx";

import padSequences from './helper/paddedSeq'

import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import StarsIcon from '@mui/icons-material/Stars';
import WarningIcon from '@mui/icons-material/Warning';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HistoryIcon from "@mui/icons-material/History";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import SearchIcon from '@mui/icons-material/Search';
import DescriptionIcon from '@mui/icons-material/Description';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import CategoryIcon from '@mui/icons-material/Category';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ForumIcon from '@mui/icons-material/Forum';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ShareIcon from '@mui/icons-material/Share';

import { Bar, Doughnut, Line, Radar} from "react-chartjs-2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import AnimatedNumber from "animated-number-react";
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';

import stockfile from "./data/stock_list.json";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import * as tf from '@tensorflow/tfjs';


const calculateCorrelation = require("calculate-correlation");

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" marginTop={1} alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} color="secondary"/>
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="initial">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function dateSubstring(myDate) {  
  return String(myDate).substring(0,10)
}


const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  tabs: {
    marginTop: 20,
  },
  TableContainer:{
    marginTop: 10,
    marginBottom:10,
    minWidth: 275,
    maxWidth: 475,
  },
  ScoreCardRed:{
    marginTop: 15,
    marginBottom:15,
    minWidth: 100,
    maxWidth: 150,
    padding:5,
    textAlign: 'center',
    backgroundColor:'rgba(227, 59, 109,0.9)',
  },
  ScoreCardGreen:{
    marginTop: 15,
    marginBottom:15,
    minWidth: 100,
    maxWidth: 150,
    padding:5,
    backgroundColor:'rgba(6, 201, 42,0.9)',
    textAlign: 'center',
    },
  ScoreCardYellow:{
    marginTop: 15,
    marginBottom:15,
    minWidth: 100,
    maxWidth: 150,
    padding:5,
    textAlign: 'center',
  },
  table: {
    maxWidth: 300,
    marginTop: 10,
  },
  title: {
    fontSize: 18,
  },
  chart: {
    minHeight: 280,
    marginTop: 15,
    marginBottom: 15,
    display: "block",
    position: "relative",
  },
  inline: {
    display: 'inline',
  },
  grid: {
    marginTop: 5,
  },
  chip: {
    margin: 5,
  },
  
});


export default function StockChart() {
  const classes = useClasses(styles);

  const { authState, authService } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const apiKey="b57c38d11emsh32976aeb83f05afp113107jsn102d78a776e1";
  const apiHost="yh-finance.p.rapidapi.com";

  // tensorflow
  const url = {

  model: 'https://storage.googleapis.com/tfjs-models/tfjs/sentiment_cnn_v1/model.json',
  metadata: 'https://storage.googleapis.com/tfjs-models/tfjs/sentiment_cnn_v1/metadata.json'
  };

  const OOV_INDEX = 2;

  const [metadata, setMetadata] = useState();
  const [model, setModel] = useState();
  const [loadModelCount, setLoadModelCount] = useState(0);


  async function loadModel(url) {
    try {
      const model = await tf.loadLayersModel(url.model);
      setModel(model);
    } catch (err) {
      console.log(err);
    }
  }

  async function loadMetadata(url) {
    try {
      const metadataJson = await fetch(url.metadata);
      const metadata = await metadataJson.json();
      setMetadata(metadata);
    } catch (err) {
      console.log(err);
    }
  }


  const getSentimentScore = (text) => {

    const inputText = text.trim().toLowerCase().replace(/(\.||)/g, '').split(' ');
    const sequence = inputText.map(word => {
      let wordIndex = metadata.word_index[word] + metadata.index_from;
      if (wordIndex > metadata.vocabulary_size) {
        wordIndex = OOV_INDEX;
      }
      return wordIndex;
    });

    const paddedSequence = padSequences([sequence], metadata.max_len);

    const input = tf.tensor2d(paddedSequence, [1, metadata.max_len]);
    const predictOut =  model.predict(input);
    const score =  predictOut.dataSync()[0];
    predictOut.dispose();

    return score;
  }


  useEffect(()=>{
    if (loadModelCount===0) {
      tf.ready().then(
        ()=>{
          loadModel(url)
          loadMetadata(url)
          setLoadModelCount(1)
        }
      );
    }
  },)


  // tensorflow
  //get username
  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      authService.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, authService]); // Update if authState changes


  const [dataStock, setDataStock] = useState("");

  const [price, setPrice] = useState(null);
  const [marketCap, setMarketCap] = useState(null);
  const [targetprice, setTargetprice] = useState(null);
  const [targetpriceperc, setTargetpriceperc] = useState(null);

  const [twohundreprice, setTwohundreprice] = useState(null);
  const [twohundrepricechange, setTwohundrepricechange] = useState(null);

  const [changepct, setChangepct] = useState(null);
  const [changepctRAW, setChangepctRAW] = useState(null);

  const [shortView, setShortView] = useState(null);
  const [midView, setMidView] = useState(null);
  const [longView, setLongView] = useState(null);

  const [yearChange, setYearChange] = useState(null);

  const [fiftytwoweekPos, setFiftytwoweekPos]=useState(null);

  const [barRevData, setbarRevData] = useState({});
  const [scatterEarningData, setScatterEarningData] = useState({});

  const [barRevDataYearly, setbarRevDataYearly] = useState({});
  const [barRecData, setBarRecData] = useState({});

  const [barOwnerData, setBarOwnerData] = useState({});
  const [barOwnerInsData, setBarOwnerInsData] = useState({});
  const [ownershipData, setOwnershipData] = useState({});


  const [yahooChat, setYahooChat]= useState([]);
  
  const [stockList, setStockList] = useState([]);
  const [rows, setRows] = useState([]);

  const [lineHistData, setLineHistData]=useState([]);
  const [barHistData, setBarHistData]=useState([]);
  const [radarData, setRadarData]=useState([]);


  const [tabvalue, setTabvalue] = React.useState(0);
  const [tabvalueO, setTabvalueO] = React.useState(0);
  const [tabvalueO0, setTabvalueO0] = React.useState(0);
  const [tabvalueO01, setTabvalueO01] = React.useState(0);

  const [myApidata, setMyApidata] = useState([]);
  const [priceHistoryArray, setPriceHistoryArray]=useState({});

  const formatValue = value => `${Number(value).toFixed(1)}`; // animated number format
// news
  const [loadingNews, setLoadingNews] = useState(true);
  const [ dataNews, setDataNews ] = useState([]);
  const [ Keyword, setKeyword] = useState(null);

  const newsKeyword=Keyword
// news

  const handleChange = (event, newValue) => {
    setTabvalue(newValue);
  };
  const handleChangeO = (event, newValue) => {
    setTabvalueO(newValue);
  };
  const handleChangeO0 = (event, newValue) => {
    setTabvalueO0(newValue);
  };
  const handleChangeO01 = (event, newValue) => {
    setTabvalueO01(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabvalue(index);
  };
  const handleChangeIndexO = (index) => {
    setTabvalueO(index);
  };
  const handleChangeIndexO0 = (index) => {
    setTabvalueO0(index);
  };
  const handleChangeIndexO01 = (index) => {
    setTabvalueO01(index);
  };

  const handleStock = (event) => {
  var stock_arr = [];
  Object.keys(stockfile).forEach(function(key) {
    stock_arr.push({"label":stockfile[key]["Symbol"], "value":stockfile[key]["Name"]});
  });
  setStockList(stock_arr)
  }
  //
  const [loadingStock, setLoadingStock] = useState(true);
  const [loadingTrending, setloadingTrending] = useState(true);

  // score initialize
  const [totalScore, setTotalScore] = useState(null);

  const [suggestion, setSuggestion] = useState(null);
  // score end

  const [chatPage, setChatPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  
  const [longSummary, setLongSummary] = useState(null);
  
  const [trendingArray, setTrendingArray] = useState([]);
  
  const [stockSPCorrelation, setStockSPCorrelation] = useState(null);
  const [stockCorrelationNasq, setStockCorrelationNasq] = useState(null);
  

  const [earingPerShare, setEaringPerShare] = useState(null);
  const [revenuePerShare, setRevenuePerShare] = useState(null);
  const [bookValuePerShare, setBookValuePerShare] = useState(null);

  const [revenueGrowth, setRevenueGrowth] = useState(null);
  const [earningGrowth, setEarningGrowth] = useState(null);
  const [cashflowPerShare, setCashflowPerShare] = useState(null);

  // const [inxPrice, setInxPrice] = useState(null);
  const [inxPricePerc, setInxPricePerc] = useState(null);

  //-------------------------
  const fetchTrendingData = async () => {
  if (loadingTrending) {

  const optionsTrends = {
    method: 'GET',
    url: 'https://yh-finance.p.rapidapi.com/market/get-trending-tickers',
    params: {region: 'US'},
    headers: {
      'x-rapidapi-key': apiKey,
      'x-rapidapi-host': apiHost
    }
  };
  let trendingList=[];

  await axios.request(optionsTrends).then(function (responseTrend) {
    
    for (
      var t = 0;
      t < responseTrend.data.finance.result[0].quotes.length;
      t++
    ) {
      var trending_item=(responseTrend.data.finance.result[0].quotes[t].regularMarketChangePercent>0?
      responseTrend.data.finance.result[0].quotes[t].symbol+" +"+responseTrend.data.finance.result[0].quotes[t].regularMarketChangePercent.toFixed(2)+"%":
      responseTrend.data.finance.result[0].quotes[t].symbol+" "+responseTrend.data.finance.result[0].quotes[t].regularMarketChangePercent.toFixed(2)+"%")
      trendingList.push(trending_item)
    }
  }).catch(function (error) {
    console.error(error);
  });
  // console.log(trendingList)
  setTrendingArray(trendingList)
  setloadingTrending(false)

};

};

fetchTrendingData()
  //---------------------------

  function searchStock(e) {
    e.preventDefault();
    var mySymbol = document.getElementById("standardStock").value;
    // eslint-disable-next-line
    const options = {
      method: "GET",
      url: "https://yh-finance.p.rapidapi.com/stock/v2/get-summary",
      params: { symbol: mySymbol, region: "US" },
      headers: {
        "x-rapidapi-key": apiKey,
        "x-rapidapi-host": apiHost,
      },
    };


    // eslint-disable-next-line
    const optionsHistory = {
      method: 'GET',
      url: 'https://yh-finance.p.rapidapi.com/market/get-charts',
      params: {
        symbol: mySymbol,
        interval: '1d',
        range: '5y',
        region: 'US',
        comparisons: '^GSPC,^IXIC'
 
      },
      headers: {
        'x-rapidapi-key': apiKey,
        'x-rapidapi-host': apiHost
      }
    };


    const fetchStockData = async () => {
         
      if (loadingStock) {

        // 1) Request Stock Price History API
        //  await axios.get('get_chart.json').then(function (responseHistory) {
        await axios.request(optionsHistory).then(function (responseHistory) {

          // console.log(responseHistory.data.chart.result[0].indicators.adjclose[0].adjclose[his])
          let histDate = [];
          let histPrice = [];
          let histSP500 = []; //^GSPC
          let histNasq = []; //^IXIC
          let hisArr=[];
          let hisTrans=[];
          // get multiplier of comparision
          var sp500Multiplier=responseHistory.data.chart.result[0].comparisons[0].close[0]/responseHistory.data.chart.result[0].indicators.adjclose[0].adjclose[0] // 10
          var nasdqMultiplier=responseHistory.data.chart.result[0].comparisons[1].close[0]/responseHistory.data.chart.result[0].indicators.adjclose[0].adjclose[0] // 50
 
          // epoch time converter
          for (
            var his = 0;
            his < responseHistory.data.chart.result[0].timestamp.length;
            his++
          ) {
            var objhis = responseHistory.data.chart.result[0].timestamp[his];
            var date = new Date(objhis * 1000);
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            histDate.push(year + "-" + month + "-" + day); // x-axis Date YYYY-MM-DD
            histPrice.push(responseHistory.data.chart.result[0].indicators.adjclose[0].adjclose[his].toFixed(2)); // daily closed price
            histSP500.push((responseHistory.data.chart.result[0].comparisons[0].close[his]/sp500Multiplier).toFixed(2)); // daily high price
            histNasq.push((responseHistory.data.chart.result[0].comparisons[1].close[his]/nasdqMultiplier).toFixed(2)); // daily low price
            hisTrans.push(((responseHistory.data.chart.result[0].indicators.quote[0].low[his]+responseHistory.data.chart.result[0].indicators.quote[0].high[his]+responseHistory.data.chart.result[0].indicators.quote[0].open[his]+responseHistory.data.chart.result[0].indicators.quote[0].close[his])*responseHistory.data.chart.result[0].indicators.quote[0].volume[his]/4).toFixed(2))
            
            
            hisArr.push({
                date: year + "-" + month + "-" + day,
                close: responseHistory.data.chart.result[0].indicators.adjclose[0].adjclose[his].toFixed(2),
                sp500: (responseHistory.data.chart.result[0].comparisons[0].close[his]/sp500Multiplier).toFixed(2),
                nasdq: (responseHistory.data.chart.result[0].comparisons[1].close[his]/nasdqMultiplier).toFixed(2),
                trans:((responseHistory.data.chart.result[0].indicators.quote[0].low[his]+responseHistory.data.chart.result[0].indicators.quote[0].high[his]+responseHistory.data.chart.result[0].indicators.quote[0].open[his]+responseHistory.data.chart.result[0].indicators.quote[0].close[his])*responseHistory.data.chart.result[0].indicators.quote[0].volume[his]/4).toFixed(2)
              })      
          }
          
          var x=histPrice.map(Number)
          var y=histSP500.map(Number)
          var z=histNasq.map(Number)

          var stockBasedPrice=x[x.length - 1]
          var spBasedPrice=y[y.length - 1]
          var nasdqBasedPrice=z[z.length - 1]
          var inxBasedPrice=(spBasedPrice+nasdqBasedPrice)/2
          // setInxPrice(inxBasedPrice)
          setInxPricePerc((((inxBasedPrice/stockBasedPrice)-1)*100).toFixed(0))

          const correlationSP = calculateCorrelation(x, y);
          const correlationNasdaq = calculateCorrelation(x, z);

          setStockSPCorrelation(correlationSP.toFixed(2))
          setStockCorrelationNasq(correlationNasdaq.toFixed(2))

          setPriceHistoryArray(hisArr) // Price Tab of Price Line Chart

          setBarHistData({
            labels: histDate,  //.reverse(),
            datasets: [
              {
                type: 'line',
                label: 'Price',
                borderColor: '#338796',
                borderWidth: 1,
                fill: false,
                data: histPrice,
                yAxisID: 'y-axis-1',

              },
              {
                type: 'bar',
                label: mySymbol+" Transaction($)",
                data: hisTrans, //.reverse(),
                backgroundColor: '#FF9671',
                yAxisID: 'y-axis-2',

              }          
            ],
          });

          setLineHistData({
            labels: histDate,  //.reverse(),
            datasets: [
              {
                label: mySymbol,
                data: histPrice, //.reverse(),
                fill: true,
                borderColor: "#338796",
              },
              {
                label: "SP500",
                data: histSP500, //.reverse(),
                fill: false,
                borderColor: "#4fad61",

              },
              {
                label: "NASDQ",
                data: histNasq, //.reverse(),
                fill: false,
                borderColor: "#ffa600",
              }          
            ],
          });
          // console.log(lineHistData) it will not reflect here immediately

        }).catch(function (error) {
          console.error(error);
        });

        // 2) Request Stock General Info API
        // let positive_count=0;
        // let negative_count=0;
        // let neutral_count=0;

        // await axios.get('stock_data.json')
        await axios.request(options)
          .then(function (response) {
            // console.log(response.data);
            setDataStock(response.data.quoteType.shortName);
            var msgBoardId=response.data.quoteType.messageBoardId;

            // conversation
            let yahoo_conversation=[]
            let yahoo_msg_id=[]
            // var loadRange;
            // for (loadRange = 0; loadRange < 29; loadRange+=9) 
            // {
              // console.log("loadRange: ",loadRange)
            
            // useEffect(() => {
            const loadUsers = async () => {
              try {
                setIsLoading(true);
                // eslint-disable-next-line
                const optionsConversation = {
                  method: 'GET',
                  url: 'https://yh-finance.p.rapidapi.com/conversations/list',
                  params: {
                    symbol: mySymbol,
                    messageBoardId: msgBoardId,
                    region: 'US',
                    userActivity: 'true',
                    sortBy: 'popular', //popular, createdAt
                    off: chatPage
                  },
                  headers: {
                    'x-rapidapi-key': apiKey,
                    'x-rapidapi-host': apiHost
                  }
                };

                // axios.get('conversation.json')
                axios.request(optionsConversation)
                .then(function (responseC) {
                responseC.data.canvassMessages.forEach(({messageId,userLabels,details, meta,reactionStats}) => {
                var createdAt=meta.createdAt;
                var dateP = new Date(createdAt * 1000);
                var yearP = dateP.getFullYear();
                var monthP = dateP.getMonth() + 1;
                var dayP = dateP.getDate();
                var providerPublishTime_converted=yearP + "-" + monthP + "-" + dayP; // x-axis Date YYYY-MM-DD
                var detailUserText=details.userText;
                var label=userLabels[0];
                var nickName=meta.author.nickname;
                var reactUp=reactionStats.upVoteCount;
                var reactDown=reactionStats.downVoteCount;
                var sentimentScore= getSentimentScore(details.userText);


                if (yahoo_msg_id.indexOf(messageId) < 0) {
                yahoo_conversation.push({messageId, label,nickName,reactUp, reactDown, detailUserText, providerPublishTime_converted, sentimentScore}); // sentimentScore
                yahoo_msg_id.push(messageId)
                }
                // yahoo_text_only.push(detailUserText)  
                }
                )

                setYahooChat(yahoo_conversation)

                }).catch(function (error) {
                console.error(error);
                });
                setErrorMsg('');
              } catch (error) {
                setErrorMsg('Error while loading data. Try again later.');
              } finally {
                setIsLoading(false);
              }
            };
          
              loadUsers();
            // }, [chatPage]);
 
          
          // console.log("Yahoo msg length :",yahoo_conversation.length);             
           // setPostiveSentiment(positive_count)
           // setNegativeSentiment(negative_count.length)
           // setNeutralSentiment(neutral_count.length)
           // conversation
            // }
            var comparableMarketCap=((response.data.price.marketCap.raw/1599000000000)*100).toFixed(2) //Goolge Market Cap

            setMarketCap(comparableMarketCap);

            setPrice(response.data.price.regularMarketPrice.fmt);
            setChangepct(response.data.price.regularMarketChangePercent.fmt);
            setChangepctRAW(response.data.price.regularMarketChangePercent.raw);
            setTargetprice(response.data.financialData.targetMedianPrice.raw);
            setTargetpriceperc(
              (
                (response.data.financialData.targetMedianPrice.raw /
                  response.data.price.regularMarketPrice.raw -
                  1) *
                100
              ).toFixed(0)
            );
            setShortView(response.data.pageViews.shortTermTrend);
            setMidView(response.data.pageViews.midTermTrend);
            setLongView(response.data.pageViews.longTermTrend);
            setYearChange((response.data.defaultKeyStatistics["SandP52WeekChange"].raw*100).toFixed(0))

            setFiftytwoweekPos(Number(((response.data.financialData.currentPrice.raw-
              response.data.summaryDetail.fiftyTwoWeekLow.raw)/(response.data.summaryDetail.fiftyTwoWeekHigh.raw-
                response.data.summaryDetail.fiftyTwoWeekLow.raw)*100).toFixed(0)))


            setLongSummary(response.data.summaryProfile.longBusinessSummary)    
            

            // Values Per share------------------------------------------------------
            var financial_quarters=response.data.earnings.financialsChart.quarterly
            var financial_years=response.data.earnings.financialsChart.yearly

            var latest_revenue=financial_quarters[0].revenue.raw+financial_quarters[1].revenue.raw+financial_quarters[2].revenue.raw+financial_quarters[3].revenue.raw
            var latest_earning=financial_quarters[0].earnings.raw+financial_quarters[1].earnings.raw+financial_quarters[2].earnings.raw+financial_quarters[3].earnings.raw

            var revenue_growth_raw=0
            if (financial_years[0].revenue.raw>0) {revenue_growth_raw=Math.pow((financial_years[3].revenue.raw/financial_years[0].revenue.raw), 1/3)-1}
            if (financial_years[1].revenue.raw>0 && financial_years[0].revenue.raw<=0) {revenue_growth_raw=Math.pow((financial_years[3].revenue.raw/financial_years[1].revenue.raw), 1/2)-1}
            if (financial_years[2].revenue.raw>0 && financial_years[0].revenue.raw<=0 && financial_years[1].revenue.raw<=0) {revenue_growth_raw=(financial_years[3].revenue.raw/financial_years[2].revenue.raw)-1}
               
            var earning_growth_raw=0
            if (financial_years[0].earnings.raw!==0) 
            {earning_growth_raw=(Math.pow(((financial_years[3].earnings.raw-financial_years[0].earnings.raw)/Math.abs(financial_years[0].earnings.raw))+1, 1/3))-1}
            if (financial_years[0].earnings.raw===0 && financial_years[1].earnings.raw!==0) 
            {earning_growth_raw=(Math.pow(((financial_years[3].earnings.raw-financial_years[1].earnings.raw)/Math.abs(financial_years[1].earnings.raw))+1, 1/2))-1}
            if (financial_years[0].earnings.raw===0 && financial_years[1].earnings.raw===0 && financial_years[2].earnings.raw!==0) 
            {earning_growth_raw=(financial_years[3].earnings.raw-financial_years[1].earnings.raw)/Math.abs(financial_years[1].earnings.raw)}
            ;

            
            setRevenueGrowth((revenue_growth_raw*100).toFixed(0))
            setEarningGrowth((earning_growth_raw*100).toFixed(0))
            setEaringPerShare((latest_earning/response.data.defaultKeyStatistics.sharesOutstanding.raw).toFixed(2))
            setRevenuePerShare((latest_revenue/response.data.defaultKeyStatistics.sharesOutstanding.raw).toFixed(2))
            setBookValuePerShare(response.data.defaultKeyStatistics.bookValue.raw)

            setCashflowPerShare((response.data.financialData.freeCashflow.raw/response.data.defaultKeyStatistics.sharesOutstanding.raw).toFixed(2))
            //------------------------------------------------------
            setOwnershipData({
              labels: ["institutions", "insiders", "retailer"],
              datasets: [
                {
                  label: "ownership",
                  backgroundColor: ["#003f5c", "#bc5090", "#c78100"],
                  hoverBackgroundColor: ["#007cb5", "#eb67b2", "#ffa600"],
                  data: [
                    (
                      response.data.majorHoldersBreakdown
                        .institutionsPercentHeld.raw * 100
                    ).toFixed(0),
                    (
                      response.data.majorHoldersBreakdown.insidersPercentHeld
                        .raw * 100
                    ).toFixed(0),
                    (
                      (1 -
                        response.data.majorHoldersBreakdown
                          .institutionsPercentHeld.raw -
                        response.data.majorHoldersBreakdown.insidersPercentHeld
                          .raw) *
                      100
                    ).toFixed(0),
                  ],
                },
              ],
            });
            
            var fwPE=0;
            var peg=0;
            var pb=0;
            var short=0;
            var gm=0;
            var np=0;
            var roe=0;
            var current=0;
            var ps=0;
            var div=0
            var avg=0;
            var tpe=0;
            var gwen=0;
            var gwrv=0;


            if ('trailingPE' in response.data.summaryDetail && response.data.summaryDetail.trailingPE.raw>20 && response.data.summaryDetail.trailingPE.raw<30) {tpe=0.5}
            if ('trailingPE' in response.data.summaryDetail && response.data.summaryDetail.trailingPE.raw>30) {tpe=-0.5}
            if ('trailingPE' in response.data.summaryDetail===false) {tpe=-1}

            if (response.data.defaultKeyStatistics.forwardPE.raw<30 && response.data.defaultKeyStatistics.forwardPE.raw>20) 
            {fwPE=1.5}
            if (response.data.defaultKeyStatistics.forwardPE.raw<0 || response.data.defaultKeyStatistics.forwardPE.raw>55 || isNaN(response.data.defaultKeyStatistics.forwardPE.raw)) 
            {fwPE=-1.5}

            if (response.data.defaultKeyStatistics.pegRatio.raw<1.25
               && response.data.defaultKeyStatistics.pegRatio.raw>0 
               ) {peg=1}
            if (response.data.defaultKeyStatistics.pegRatio.raw>4 || response.data.defaultKeyStatistics.pegRatio.raw<-4 || isNaN(response.data.defaultKeyStatistics.pegRatio.raw)) {peg=-1}

            if (response.data.defaultKeyStatistics.priceToBook.raw<3.5) {pb=1}
            if (response.data.defaultKeyStatistics.priceToBook.raw>10 || isNaN(response.data.defaultKeyStatistics.priceToBook.raw)) {pb=-1}

            if (response.data.defaultKeyStatistics.shortRatio.raw>4) {short=-1}

            if (response.data.summaryDetail.dividendRate.raw>0) {div=0.5}

            if (response.data.financialData.grossMargins.raw>0.4) {gm=1}
            if (response.data.financialData.grossMargins.raw<0.15) {gm=-1}

            if (response.data.financialData.profitMargins.raw>0.2) {np=1}
            if (response.data.financialData.profitMargins.raw<-0.1) {np=-1}
            
            if (revenue_growth_raw>=0.9) {gwrv=4}
            if (revenue_growth_raw>=0.4 && revenue_growth_raw<0.9) {gwrv=2}
            if (revenue_growth_raw<0.4 && revenue_growth_raw>=0.2) {gwrv=1}
            if (revenue_growth_raw<0.2 && revenue_growth_raw>=0.1) {gwrv=0.5}
            if (revenue_growth_raw<0 || isNaN(revenue_growth_raw)) {gwrv=-4}


            if (earning_growth_raw>0.4) {gwen=2}
            if (earning_growth_raw<=0.4 && earning_growth_raw>=0.15) {gwen=1}
            if (earning_growth_raw<0.15 && earning_growth_raw>=0) {gwen=-0.5}
            if (earning_growth_raw<0 ||  isNaN(earning_growth_raw)) {gwen=-2}

            if (response.data.financialData.returnOnEquity.raw>0.15) {roe=1}
            if (response.data.financialData.returnOnEquity.raw<-0.15 || isNaN(response.data.financialData.returnOnEquity.raw)) {roe=-1}

            if (response.data.financialData.currentRatio.raw>1.5) {current=1}
            if (response.data.financialData.currentRatio.raw<=1 || isNaN(response.data.financialData.currentRatio.raw)) {current=-1}

            if (response.data.price.regularMarketPrice.raw/response.data.financialData.revenuePerShare.raw<5 && response.data.price.regularMarketPrice.raw/response.data.financialData.revenuePerShare.raw>=2) {ps=3}
            if (response.data.price.regularMarketPrice.raw/response.data.financialData.revenuePerShare.raw>8) {ps=-3}
            if (response.data.price.regularMarketPrice.raw/response.data.financialData.revenuePerShare.raw<2 && response.data.price.regularMarketPrice.raw/response.data.financialData.revenuePerShare.raw>0) {ps=1.5}

            if (((response.data.price.regularMarketPrice.raw/response.data.summaryDetail.twoHundredDayAverage.raw)-1)<0) {avg=0.5}
            if (((response.data.price.regularMarketPrice.raw/response.data.summaryDetail.twoHundredDayAverage.raw)-1)>0.1) {avg=-0.5}

            
            var sug=''
            var mytotal=fwPE+peg+pb+short+gm+np+roe+current+ps+avg+div+tpe+gwen+gwrv
            if (mytotal>6.5) {
               sug="Buy"
            }
            else if (mytotal<-0.5)
            {sug="Sell"} else {sug="Hold"}

            
            // radar analysis
            var Momentum_radar=(fwPE+avg+peg+ps>0?(fwPE+avg+peg+ps)*1.5:0)
            var Growth_radar=(ps+gwen+gwrv>0? (ps+gwen+gwrv)*1.2:0)
            var Value_radar=((div+pb+gm+np+roe+tpe)>0?(div+pb+gm+np+roe+tpe)*1.2:0) 
            var Risk_radar=((short+current+fwPE+tpe+ps+peg)*-1.5>0?(short+current+fwPE+tpe+ps)*-1.5:0) 



            // Analysis comment
              
            var fwPE_analysis= (fwPE>0? " Strong FW PE; ":fwPE<0?" FW PE is risky: "+response.data.defaultKeyStatistics.forwardPE.fmt+"; ":"");
            var peg_analysis= (peg>0? " Solid PEG Ratio; ":peg<0? " Highly OverValued Stock Price; ":"");
            var pb_analysis= (pb>0? " Strong Price to Book Ratio; ":"");
            var short_analysis= (short<0? " Currently being Shorted(Risky): "+response.data.defaultKeyStatistics.shortRatio.fmt+"; ":"");
            var div_analysis= (div>0? " paying Dividend; ":"");
            var gm_analysis= (gm>0? " Strong Gross Margin: "+response.data.financialData.grossMargins.fmt+"; ":"");
            var np_analysis= (np>0? " Strong Net Profit: "+response.data.financialData.profitMargins.fmt+"; ":"");
            var earning_growth_analysis= (gwen>1? " Strong Earning Growth: "+earning_growth_raw+"; ":
                                          gwen<0? " Weak Earning Growth: "+earning_growth_raw+"; ":"");
            
            var revenue_growth_analysis= (gwrv>0.5? " Strong Revenue Growth: "+revenue_growth_raw+"; ":"");
                                          
            var roe_analysis= (roe>0? " Strong Return To Equity Ratio; ":"");
            var current_analysis= (current>0? " Strong Current Ratio; ":""); 
            
            var ps_analysis= (ps>0? " Nice StockPrice/Revenue Ratio; ":"");
            var avg_analysis= (avg>0? " Currently lower enough than 200 Days Avg; ":"");

            // eslint-disable-next-line
            const comment_name = "["+sug+"]"+" highlighted with"+fwPE_analysis +peg_analysis+pb_analysis+short_analysis
                              +div_analysis+gm_analysis+np_analysis+earning_growth_analysis+revenue_growth_analysis
                              +roe_analysis+current_analysis+ps_analysis+avg_analysis;
            const user_name = userInfo.name? userInfo.name : "Unkown";
            const Rating = mytotal;
            const Description = "["+sug+"] at $"+ response.data.price.regularMarketPrice.fmt;
            const Brand = mySymbol;
            const parameter = {"FW_PE": response.data.defaultKeyStatistics.forwardPE.raw,
                              "Gross_Margin":response.data.financialData.grossMargins.raw,
                              "Net_Profit":response.data.financialData.profitMargins.raw,
                              "PEG_ratio":response.data.defaultKeyStatistics.pegRatio.raw,
                              "Short_ratio":response.data.defaultKeyStatistics.shortRatio.raw,
                              "ROE":response.data.financialData.returnOnEquity.raw,
                              "Current_ratio":response.data.financialData.currentRatio.raw,
                              "current_to_200":(response.data.price.regularMarketPrice.raw/response.data.summaryDetail.twoHundredDayAverage.raw)-1,
                              "earning_growth":earning_growth_raw.toFixed(2),
                              "revenue_growth": revenue_growth_raw.toFixed(2)
                              };

            if (mySymbol!=="") {
              axios.post("https://20211205t040925-dot-my-comment-api-dot-mingfei-test-account.appspot.com/api/comments", { comment_name, user_name, Rating, Description, Brand, parameter}) //"http://localhost:8080/api/comments"
              .then(res => {
                // console.log(res);
                console.log(res.data);
              })
              .catch(error => {
                console.log(error.response)
            })}
          

            setRadarData({
              labels: ['Speculation', 'Growth', 'Risk', 'Value'],
              datasets: [
                {
                  label: 'Analysis',
                  data: [Momentum_radar, Growth_radar, Risk_radar,Value_radar],
                  backgroundColor: 'rgba(255, 99, 132, 0.2)',
                  borderColor: 'rgba(255, 99, 132, 1)',
                  borderWidth: 1,
                },
              ],
            })


            setTotalScore(mytotal)
            setSuggestion(sug)         
            
            setRows([ 
              {
                name: "Trailing PE",
                value: ('trailingPE' in response.data.summaryDetail?response.data.summaryDetail.trailingPE.fmt:0),
                score: tpe,
              },         
              {
                name: "Forward PE",
                value: response.data.defaultKeyStatistics.forwardPE.fmt,
                score: fwPE,
              },
              {
                name: "PEG ratio (better <1)",
                value: response.data.defaultKeyStatistics.pegRatio.fmt,
                score: peg
              },
              {
                name: "Price To Book (better <3)",
                value: response.data.defaultKeyStatistics.priceToBook.fmt,
                score: pb
              },
              {
                name: "Short Ratio (>4.5 warning, >8 is dangerious)",
                value: response.data.defaultKeyStatistics.shortRatio.fmt,
                score: short

              },
              {
                name: "Dividend $",
                value: response.data.summaryDetail.dividendRate.fmt
                  ? response.data.summaryDetail.dividendRate.fmt
                  : "0",
                score: div
              },
              {
                name: "Gross Margin%",
                value: response.data.financialData.grossMargins.fmt,
                rawValue: response.data.financialData.grossMargins.raw,
                score: gm
              },
              {
                name: "Net Profit%",
                value: response.data.financialData.profitMargins.fmt,
                rawValue: response.data.financialData.profitMargins.raw,
                score: np
              },

              {
                name: "ROE",
                value: response.data.financialData.returnOnEquity.fmt,
                rawValue: response.data.financialData.returnOnEquity.raw,
                score: roe
              },
              {
                name: "Current Ratio (>1)",
                value: response.data.financialData.currentRatio.fmt,
                score: current

              },
              {
                name: "Price to Sales Ratio (range 2~5)",
                value: (response.data.price.regularMarketPrice.raw/response.data.financialData.revenuePerShare.raw).toFixed(1),
                score: ps
              },
            ]);

            setTwohundreprice(
              response.data.summaryDetail.twoHundredDayAverage.fmt
            );

            setTwohundrepricechange(
              parseFloat(
                (response.data.price.regularMarketPrice.raw /
                  response.data.summaryDetail.twoHundredDayAverage.raw -
                  1) *
                  100
              ).toFixed(2)
            );
            // Quarterly EARNING/Revnue
            let chartLable = [];
            let chartValue = [];
            let chartValueRevenu = [];

            for (
              var i = 0;
              i < response.data.earnings.financialsChart.quarterly.length;
              i++
            ) {
              var obj = response.data.earnings.financialsChart.quarterly[i];
              chartLable.push(obj.date); // x-axis
              chartValue.push(obj.earnings.raw / 1000000); // Quarterly EARNING
              chartValueRevenu.push(obj.revenue.raw / 1000000); // Quarterly Revenu
            }

            // Yearly EARNING/Revnue
            let chartLableYearly = [];
            let chartValueYearly = [];
            let chartValueRevenuYearly = [];

            for (
              var x = 0;
              x < response.data.earnings.financialsChart.yearly.length;
              x++
            ) {
              var objy = response.data.earnings.financialsChart.yearly[x];
              chartLableYearly.push(objy.date); // x-axis
              chartValueYearly.push(objy.earnings.raw / 1000000); // Yearly EARNING
              chartValueRevenuYearly.push(objy.revenue.raw / 1000000); // Yearly Revenu
            }


             // Quarterly actual/estimate
             let earningChartLable = [];
             let earningChartActual = [];
             let earningChartEstimate = [];
 
             for (
               var er = 0;
               er < response.data.earnings.earningsChart.quarterly.length;
               er++
             ) {
               var objer = response.data.earnings.earningsChart.quarterly[er];
               earningChartLable.push(objer.date); // x-axis
               earningChartActual.push(objer.actual.raw); // Quarterly actual
               earningChartEstimate.push(objer.estimate.raw); // Quarterly estimate
             }
            if (response.data.calendarEvents.earnings.earningsDate.length>0) {            
            earningChartLable.push(response.data.calendarEvents.earnings.earningsDate[0].fmt+" Estmated")
            earningChartEstimate.push(response.data.calendarEvents.earnings.earningsAverage.raw)}

            // Recommandation
            let recLable = [];
            let strongBuy = [];
            let buy = [];
            let hold = [];
            let sell = [];
            let strongSell = [];

            for (
              var rec = 0;
              rec < response.data.recommendationTrend.trend.length;
              rec++
            ) {
              var objrec = response.data.recommendationTrend.trend[rec];
              recLable.push(objrec.period); // x-axis
              strongBuy.push(objrec.strongBuy);
              buy.push(objrec.buy);
              hold.push(objrec.hold);
              sell.push(objrec.sell);
              strongSell.push(objrec.strongSell);
            }

            // Funds Ownership

            let fundOwner = [];
            let fundOwnerPerc = [];
            for (
              var o = 0;
              o < response.data.fundOwnership.ownershipList.length;
              o++
            ) {
              var objo = response.data.fundOwnership.ownershipList[o];
              fundOwner.push(objo.organization); // x-axis
              fundOwnerPerc.push(objo.pctHeld.raw * 100); // perc
            }
            // ins Funds Ownership

            let insOwner = [];
            let insOwnerPerc = [];
            for (
              var xins = 0;
              xins < response.data.institutionOwnership.ownershipList.length;
              xins++
            ) {
              var objins = response.data.institutionOwnership.ownershipList[xins];
              insOwner.push(objins.organization); // x-axis
              insOwnerPerc.push(objins.pctHeld.raw * 100); // perc
            }


            setbarRevData({
              labels: chartLable,
              datasets: [
                {
                  label: "Quarterly Earning (Million)",
                  backgroundColor: "#38a18b",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#52edcd",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: chartValue,
                },
                {
                  label: "Quarterly Revenue (Million)",
                  backgroundColor: "#739d41",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#91c056",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: chartValueRevenu,
                },
              ],
            });

            setbarRevDataYearly({
              labels: chartLableYearly,
              datasets: [
                {
                  label: "Yearly Earning (Million)",
                  backgroundColor: "#38a18b",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#52edcd",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: chartValueYearly,
                },
                {
                  label: "Yearly Revenue (Million)",
                  backgroundColor: "#739d41",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#91c056",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: chartValueRevenuYearly,
                },
              ],
            });

            
            setScatterEarningData({
              labels: earningChartLable,
              datasets: [

                {
                  label: "Earning Estimate",
                  backgroundColor: "#db4063",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#ff4b7c",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: earningChartEstimate,
                },
                {
                  label: "Earning Actual",
                  backgroundColor: "#572f73",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#a459d9",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: earningChartActual,
                },
              ],
            });

            setBarRecData({
              labels: recLable,
              datasets: [
                {
                  label: "Strong Buy",
                  backgroundColor: "#386b6a",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#57a6a4",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: strongBuy,
                },
                {
                  label: "Buy",
                  backgroundColor: "#328567",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#4fb38d",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: buy,
                },
                {
                  label: "Hold",
                  backgroundColor: "#5f9b4b",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#78b960",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: hold,
                },
                {
                  label: "Sell",
                  backgroundColor: "#a7a81c",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#b6b728",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: sell,
                },
                {
                  label: "Strong Sell",
                  backgroundColor: "#ffa600",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#ffa600",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: strongSell,
                },
              ],
            });

            setBarOwnerInsData({
              labels: insOwner,
              datasets: [
                {
                  label: "Institution Ownership Ranking %",
                  backgroundColor: "#38a18b",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#52edcd",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: insOwnerPerc,
                },
              ],
            });

            setBarOwnerData({
              labels: fundOwner,
              datasets: [
                {
                  label: "Ownership Ranking %",
                  backgroundColor: "#38a18b",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 1,
                  hoverBackgroundColor: "#52edcd",
                  hoverBorderColor: "rgba(255, 255, 255, 1)",
                  data: fundOwnerPerc,
                },
              ],
            });
          })
          .catch(function (error) {
            console.error(error);
          });
          console.log("loading completed loading STATUS:" + loadingStock);

        } else {
          console.log("Stop loading STATUS:" + loadingStock);
        }

      
      // get Comment API data
    const options_myapi = {
      method: "GET",
      url: "https://20211205t040925-dot-my-comment-api-dot-mingfei-test-account.appspot.com/api/comments", //"http://localhost:8080/api/comments"
    };

      await axios.request(options_myapi).then(function(res){
        let targeted_comment=[]
        for (
          var tc = 0;
          tc < res.data.length;
          tc++
        ) {
          if (res.data[tc].Brand===mySymbol) {
            // var res = str.substring(1, 4);
            res.data[tc].updatedAt=dateSubstring(res.data[tc].updatedAt)
            res.data[tc].sentimentScore=getSentimentScore(res.data[tc].comment_name)
            targeted_comment.push(res.data[tc])
          }
        }
      setMyApidata(targeted_comment)
      // console.log("my api data: ",targeted_comment)
        
      })
      .catch(error => {
        console.log(error)
      });

      setLoadingStock(true)

    };
    fetchStockData();

    
    //START News
    
    // eslint-disable-next-line
    const newsOptions = {
      method: 'GET',
      url: 'https://yh-finance.p.rapidapi.com/auto-complete',
      params: {q: mySymbol, region: 'US'},
      headers: {
        'x-rapidapi-key': apiKey,
        'x-rapidapi-host': apiHost
      }
    };
    // var newsApi="https://newsapi.org/v2/everything?q="+newsKeyword+"&apiKey="+newApiKey
    setLoadingNews(true)
    const fetchNewsData = async () => {
      if (loadingNews){
      let myNews = [];
        
      // await axios.get('auto_complete.json')
      await axios.request(newsOptions)
        .then(function (response) {
          // console.log(response.data.news);
          response.data.news.forEach(({uuid,type, title, publisher, providerPublishTime, link}) => {
            if (type==="STORY") { //remove null description

              var dateP = new Date(providerPublishTime * 1000);
              var yearP = dateP.getFullYear();
              var monthP = dateP.getMonth() + 1;
              var dayP = dateP.getDate();
              var providerPublishTime_converted=yearP + "-" + monthP + "-" + dayP; // x-axis Date YYYY-MM-DD
                myNews.push({uuid,type, title, publisher, providerPublishTime_converted, link});
              }
          })


        }).catch(function (error) {
          console.error(error);
        });


    setKeyword(mySymbol.toUpperCase())
    setDataNews(myNews);
  }
    }
    fetchNewsData()

  // END
  
  }

  const loadMore = async() => {
    setChatPage((chatPage) => chatPage + 9);
    // console.log("Load More page: ", chatPage)
  };

  const changeHistoryRange = async(xdate) => {   
    var today = new Date();  
    var oneMonthAgo = new Date().setDate(today.getDate()-xdate)
    var lineChartDate = [];

    for (
      var MonthL = 0;
      MonthL < priceHistoryArray.length; //priceHistoryDate.length;
      MonthL++
    ) {
      
      if (new Date(priceHistoryArray[MonthL].date).getTime()>oneMonthAgo) {
      lineChartDate.push(priceHistoryArray[MonthL]);
      }
    }

    var datelist = [];
    var closelist = [];
    var sp500list = [];
    var nasdqlist = [];
    var translist = [];

    var sp500MultiplierCut=lineChartDate[0].close/lineChartDate[0].sp500
    var nasdqMultiplierCut=lineChartDate[0].close/lineChartDate[0].nasdq


    for (
      var i=0;
      i<lineChartDate.length;
      i++) {
        datelist.push(lineChartDate[i].date)
        closelist.push(lineChartDate[i].close)
        sp500list.push((lineChartDate[i].sp500*sp500MultiplierCut).toFixed(2))
        nasdqlist.push((lineChartDate[i].nasdq*nasdqMultiplierCut).toFixed(2))
        translist.push(lineChartDate[i].trans)
    }
    //--------------get correlation data array to compare with indexes------------------

    var x=closelist.map(Number)
    var y=sp500list.map(Number)
    var z=nasdqlist.map(Number)
    //get last trade day price to compare with indexes
    var stockBasedPrice=x[x.length - 1]
    var spBasedPrice=y[y.length - 1]
    var nasdqBasedPrice=z[z.length - 1]
    var inxBasedPrice=(spBasedPrice+nasdqBasedPrice)/2
    setInxPricePerc((((inxBasedPrice/stockBasedPrice)-1)*100).toFixed(0))

    const correlationSP = calculateCorrelation(x, y);
    const correlationNasdaq = calculateCorrelation(x, z);

    setStockSPCorrelation(correlationSP.toFixed(2))
    setStockCorrelationNasq(correlationNasdaq.toFixed(2))
    //--------------------------------------------------
    setBarHistData({
      labels: datelist,  //.reverse(),
      datasets: [
        {
          type: 'line',
          label: 'Price',
          borderColor: '#338796',
          borderWidth: 1,
          fill: false,
          data: closelist,
          yAxisID: 'y-axis-1',

        },
        {
          type: 'bar',
          label: Keyword+" Transaction($)",
          data: translist, //.reverse(),
          backgroundColor: '#FF9671',
          yAxisID: 'y-axis-2',

        }          
      ],
    });

    setLineHistData({
      labels: datelist, // lineChartDate, histDate.reverse(),
      datasets: [
        {
          label: Keyword,
          data: closelist, //.reverse(),
          fill: true,
          borderColor: "#338796",


        },
        {
          label: "SP500",
          data: sp500list, //.reverse(),
          fill: false,
          borderColor: "#4fad61",

        },
        {
          label: "Nasdq",
          data: nasdqlist, //.reverse(),
          fill: false,
          borderColor: "#ffa600",

        }
      ],
    }); 

  };

  return (
    <div>
    <FormControl
      style={{
        display: "block",
        textAlign: 'left',
        margin:'10px',
      }} 
      >

      <Autocomplete
        id="standardStock"
        inputValue={JSON.stringify(stockList[0])}
        freeSolo
        options={stockList}
        getOptionLabel={(option) => option.label}
        renderOption={(props,option) => (
          <li {...props}>{option.label}&nbsp;&nbsp;&nbsp;{option.value}</li>
        )}
  
        onInputChange={handleStock}
        defaultValue={newsKeyword}
        style={{maxWidth:800, minWidth:300, marginBottom:10}}

        renderInput={(params) => <TextField {...params} label="Stock Symbol" />}
      />



        {/* <Autocomplete
        id="standardStock"
        inputValue={JSON.stringify(stockList[0])}
        onInputChange={handleStock}
        defaultValue={newsKeyword}
        autoHighlight
        style={{maxWidth:800, minWidth:300}}
        options={stockList}
        getOptionLabel={(option) => option.label}
        renderOption={(option) => (
        <React.Fragment>
        {option.label} ({option.value})
        </React.Fragment>
        )}
        renderInput={(params) => <TextField {...params} label="Stock" margin="normal"/>} 
        /> */}
        
        {userInfo? (<Button
          style={{ marginBottom: 12}}
          onClick={searchStock}
          variant="contained"
          color="primary"
        >
          <SearchIcon /> Search
        </Button>):(
          <div>
          <Button
          style={{ marginBottom: 12}}
          onClick={searchStock}
          variant="contained"
          color="primary"
          disabled
        >
          <SearchIcon /> Search
        </Button>
        <Typography><ErrorOutlineIcon/>&nbsp; Your Login Has Been Expired, Please Refresh Page or Login Again</Typography>
        </div>
        )}
        <Divider style={{margin:15}}/>
        {changepctRAW ? <div></div>
        :<div>
          <h6><TrendingUpIcon/> Trending Now</h6>
            {trendingArray.map(function(name, index){
              if (name.includes("+")) {
              return <Chip label={name} key={index} className={classes.chip} style={{backgroundColor:'#6ba56a'}} clickable />
            } else return <Chip label={name} key={index} className={classes.chip} clickable style={{backgroundColor:'#d53353'}}/>           
            })}
        </div>}
        
      </FormControl>
        {/* {trendingList.length>0 && trendingList.map(itemTrending => <Chip label={itemTrending} component="a" href="#chip" clickable />)} */}


        <h3>{dataStock}</h3>{" "}
        {changepctRAW? (
        <Button
        style={{ marginBottom: 12}}
        // onClick={share}
        display= "flex"
        variant="contained"
        color="secondary"
      >
        <ShareIcon /> Share
      </Button>):<div></div>}

        <h4>{price}</h4>
        
        

      {changepctRAW > 0 ? (
        <p style={{ marginTop: "1em", color: "rgba(6, 201, 42,0.9)" }}>
          <ArrowUpwardIcon />
          {changepct}
        </p>
      ) : changepctRAW < 0 ? (
        <p style={{ marginTop: "1em", color: "rgba(227, 59, 109,0.9)" }}>
          <ArrowDownwardIcon />
          {changepct}
        </p>
      ) : changepctRAW === 0 ? (
        <div></div>
      ) : (
        <div></div>
      )}


      {/* score card */}
      {changepctRAW? (<div>
      <Card className={suggestion==='Sell'? classes.ScoreCardRed:suggestion==='Buy'?classes.ScoreCardGreen:classes.ScoreCardYellow}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
         {suggestion}
        </Typography>
        <Typography variant="h5" component="h2">
        <AnimatedNumber value={totalScore} formatValue={formatValue} duration={3000}/>
        </Typography>
      </CardContent>
      </Card>
        </div>):<div></div>}
      {/* score card */}

      {lineHistData.length===0?(<div></div>):
      <div>
      <Button
      style={{ marginBottom: 12}}
      onClick={(state) => changeHistoryRange(7)}
      > 5D
      </Button>
      <Button
      style={{ marginBottom: 12}}
      onClick={(state) => changeHistoryRange(30)}
      > 1M
      </Button>

      <Button
      style={{ marginBottom: 12}}
      onClick={(state) => changeHistoryRange(90)}
      > 3M
      </Button>
      <Button
      style={{ marginBottom: 12}}
      onClick={(state) => changeHistoryRange(180)}
      > 6M
      </Button>
      <Button
      style={{ marginBottom: 12}}
      onClick={(state) => changeHistoryRange(360)}
      > 1Y
      </Button>
      <Button
      style={{ marginBottom: 12}}
      onClick={(state) => changeHistoryRange(1500)}
      > Max
      </Button>
      </div>
      }

  
      {lineHistData.length===0?(<div></div>):
          <div className={classes.tabs}>
          <AppBar position="static" color="default">
          <Tabs
          value={tabvalueO0}
          onChange={handleChangeO0}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          >
          <Tab label="Price" {...a11yProps(0)} />
          <Tab label="Transaction" {...a11yProps(1)} />

          </Tabs>
          </AppBar>

          <SwipeableViews
          axis="x"
          index={tabvalueO0}
          onChangeIndex={handleChangeIndexO0}
          >
          <TabPanel value={tabvalueO0} index={0} dir="x" className={classes.chart}>
          <div className={classes.chart}>
          <Line data={lineHistData}
          options={{
          responsive: true,
          maintainAspectRatio: false,
          point:{
          radius: 0
          },
          tooltips: {
          mode: 'label'
          },
          hover: {
          mode: 'dataset'
          },
          scales:{
            xAxes: [
            {
            type: "time",
            time: { unit: "day", displayFormats: { day: "YYYY-MM-DD" } },
            },
            ]}
          }}
          />
          </div>
          </TabPanel>
          <TabPanel value={tabvalueO0} index={1} dir="x" className={classes.chart}>
          <div className={classes.chart}>
          <Bar
          data={barHistData}          
            options={{
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {mode: 'label'},
            hover: {
            mode: 'dataset'
            },
            scales:{
              xAxes: [
              {
              type: "time",
              time: { unit: "day", displayFormats: { day: "YYYY-MM-DD" } },
              },
              ],
              yAxes: [
                {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  id: 'y-axis-1',
                },
                {
                  type: 'linear',
                  display: true,
                  position: 'right',
                  id: 'y-axis-2',
                  gridLines: {
                    drawOnArea: false,
                  },
                },
              ],
              }
          }}
        />
          </div>
          </TabPanel>
          </SwipeableViews>
          </div>
      }



      {twohundrepricechange > 0 ? (
        <div>
            <HistoryIcon /> &nbsp;200 Days Avg: {twohundreprice} &nbsp;
          <span style={{ marginTop: "1em", color: "rgba(6, 201, 42,0.9)" }}>
            <TrendingUpIcon />&nbsp;
            +{twohundrepricechange}%&nbsp;
            above the 200 Days average &nbsp;
          </span>
          </div>

      ) : twohundrepricechange < 0 ? (
        <div>
          <HistoryIcon /> &nbsp;200 Days Avg: {twohundreprice} &nbsp;
          <span style={{ marginTop: "1em", color: "rgba(227, 59, 109,0.9)" }}>
            <TrendingDownIcon />&nbsp;
            {twohundrepricechange}% &nbsp;
            Below the 200 Days average &nbsp;
          
          {twohundrepricechange<-10?  
          <span
          style={{
            color: "#ffa600" ,
          }}><StarsIcon/></span>
          :twohundrepricechange>10?<p
          style={{
          color: "rgba(227, 59, 109,0.9)" ,
          }}
          ><WarningIcon/></p>
          :<span></span>}
          </span>
        </div>
      ) : twohundrepricechange === 0 ? (
        <div></div>
      ) : (
        <div></div>
      )}

      {fiftytwoweekPos > 0 ?(
      <div className={classes.table}>
        <LocationOnIcon />&nbsp;Current Price Position (52 weeks): <span><LinearProgressWithLabel value={fiftytwoweekPos}/></span>
      </div>):(
      <div></div>
      )}

      { marketCap > 0 ? (
      <div><MonetizationOnIcon/>&nbsp; Market Cap≈{marketCap}% &nbsp;Google</div>
      ): (
        <div></div>
      )}
      
      { revenuePerShare ? (
      <div><BeenhereIcon/>&nbsp; Revenue Per Share: {revenuePerShare} $</div>
      ): (
        <div></div>
      )}
      { revenueGrowth ? (
      <div><MonetizationOnIcon/>&nbsp; Yearly Revenue Growth: {revenueGrowth} %
      {revenueGrowth>=40?  
          <span
          style={{
            color: "#ffa600" ,
          }}><StarsIcon/></span>:<span></span>}
      </div>
      ): (
        <div></div>
      )}

      { earingPerShare ? (
      <div><CategoryIcon/>&nbsp; Earning Per Share: {earingPerShare} $</div>
      ): (
        <div></div>
      )}
      { earningGrowth ? (
      <div><MonetizationOnIcon/>&nbsp; Yearly Earning Growth: {earningGrowth} %
      {earningGrowth>=30?  
          <span
          style={{
            color: "#ffa600" ,
          }}><StarsIcon/></span>:<span></span>}
      </div>
      ): (
        <div></div>
      )}

      { bookValuePerShare > 0 ? (
      <div><AttachMoneyIcon/>&nbsp; Book Value Per Share: {bookValuePerShare} $</div>
      ): (
        <div></div>
      )}
      { cashflowPerShare ? (
      <div><AttachMoneyIcon/>&nbsp; Cashflow Per Share: {cashflowPerShare} $</div>
      ): (
        <div></div>
      )}



      <Grid container className={classes.grid} spacing={2}>

        {yearChange > 0 ? (
          <Grid item xs={3}>
            <Box bgcolor="success.main" color="success.contrastText" p={2}>
              <p>
                <TrendingUpIcon />
                &nbsp;52 Weeks Change: +{yearChange}% 
              </p>
            </Box>
          </Grid>
      ) : yearChange < 0 ? (
          <Grid item xs={3}>
            <Box bgcolor="error.main" color="error.contrastText" p={2}>
              <p>
                <TrendingDownIcon />
                &nbsp;52 Weeks Change: {yearChange}%
              </p>
            </Box>
          </Grid>
      ) : (
        <div></div>
      )}

      {targetpriceperc > 0 ? (
          <Grid item xs={3}>
            <Box bgcolor="success.main" color="success.contrastText" p={2}>
              <p>
                <TrackChangesIcon />
                &nbsp;Target Price: <b>${targetprice}</b> Potential {targetpriceperc}%
              </p>
            </Box>
          </Grid>
      ) : targetpriceperc < 0 ? (
          <Grid item xs={3}>
            <Box bgcolor="error.main" color="error.contrastText" p={2}>
              <p>
                <TrackChangesIcon />
                Target Price: <b>${targetprice}</b> {targetpriceperc}% over vs.
                current
              </p>
            </Box>
          </Grid>
      ) : (
        <div></div>
      )}

      {stockSPCorrelation > 0 ? (
                <Grid item xs={3}>
                  <Box bgcolor="success.main" color="success.contrastText" p={2}>
                    <p>
                      <HistoryIcon />
                      &nbsp;vs SP 500 Correlation: <b>{stockSPCorrelation}</b>
                    </p>
                  </Box>
                </Grid>
            ) : stockSPCorrelation < 0 ? (
                <Grid item xs={3}>
                  <Box bgcolor="error.main" color="error.contrastText" p={2}>
                    <p>
                      <HistoryIcon />
                      &nbsp;vs SP 500 Correlation: <b>{stockSPCorrelation}</b> 
                    </p>
                  </Box>
                </Grid>
            ) : (
              <div></div>
            )} 

      {stockCorrelationNasq > 0 ? (
                <Grid item xs={3}>
                  <Box bgcolor="success.main" color="success.contrastText" p={2}>
                    <p>
                      <HistoryIcon />
                      &nbsp;vs Nasdaq Correlation: <b>{stockCorrelationNasq}</b>
                    </p>
                  </Box>
                </Grid>
            ) : stockCorrelationNasq < 0 ? (
                <Grid item xs={3}>
                  <Box bgcolor="error.main" color="error.contrastText" p={2}>
                    <p>
                      <HistoryIcon />
                      &nbsp;vs Nasdaq Correlation: <b>{stockCorrelationNasq}</b> 
                    </p>
                  </Box>
                </Grid>
            ) : (
              <div></div>
            )}

      </Grid>


      <div>
      <Grid container className={classes.root} spacing={2} direction="row">
      {inxPricePerc > 0 ? (
                <Grid item xs={3}>
                  <Box bgcolor="success.main" color="success.contrastText" p={2}>
                    <p>
                      <HistoryIcon />
                      &nbsp;Mid Term Potential: &nbsp;<b>{inxPricePerc} %</b>
                    </p>
                  </Box>
                </Grid>
            ) : inxPricePerc < 0 ? (
                <Grid item xs={3}>
                  <Box bgcolor="error.main" color="error.contrastText" p={2}>
                    <p>
                      <HistoryIcon />
                      &nbsp;Mid Term Risk: &nbsp;<b>{inxPricePerc} %</b> 
                    </p>
                  </Box>
                </Grid>
            ) : (
              <div></div>
            )}



      {shortView === "UP" ? (
          <Grid item xs={3}>
            <Box bgcolor="success.main" color="success.contrastText" p={2}>
              <p>
                <ArrowUpwardIcon />
                &nbsp; Short Term Views: <b>{shortView}</b>
              </p>
            </Box>
        </Grid>
      ) : shortView === "DOWN" ? (
          <Grid item xs={3}>
            <Box bgcolor="error.main" color="error.contrastText" p={2}>
              <p>
                <ArrowDownwardIcon />
                &nbsp;Short Term Views: <b>{shortView}</b>
              </p>
            </Box>
        </Grid>
      ) : (
        <div></div>
      )}

      {midView === "UP" ? (
          <Grid item xs={3}>
            <Box bgcolor="success.main" color="success.contrastText" p={2}>
              <p>
                <ArrowUpwardIcon />
                &nbsp;Mid Term Views: <b>{midView}</b>
              </p>
            </Box>
        </Grid>
      ) : midView === "DOWN" ? (
          <Grid item xs={3}>
            <Box bgcolor="error.main" color="error.contrastText" p={2}>
              <p>
                <ArrowDownwardIcon />
                &nbsp;Mid Term Views: <b>{midView}</b>
              </p>
            </Box>
        </Grid>
      ) : (
        <div></div>
      )}

      {longView === "UP" ? (
          <Grid item xs={3}>
            <Box bgcolor="success.main" color="success.contrastText" p={2}>
              <p>
                <ArrowUpwardIcon />
                &nbsp;Long Term Views: <b>{longView}</b>
              </p>
            </Box>
        </Grid>
      ) : longView === "DOWN" ? (
          <Grid item xs={3}>
            <Box bgcolor="error.main" color="error.contrastText" p={2}>
              <p>
                <ArrowDownwardIcon />
                &nbsp;Long Term Views: <b>{longView}</b>
              </p>
            </Box>
        </Grid>
      ) : (
        <div></div>
      )}
        </Grid>

      </div>
      




      {changepctRAW ? (
        <div>
          <div className={classes.tabs}>
          <AppBar position="static" color="default">
          <Tabs
          value={tabvalueO01}
          onChange={handleChangeO01}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          >
          <Tab label="Ratios" {...a11yProps(0)} />
          <Tab label="Analysis" {...a11yProps(1)} />
          </Tabs>
          </AppBar>
          <SwipeableViews
          axis="x"
          index={tabvalueO01}
          onChangeIndex={handleChangeIndexO01}
          >
          <TabPanel value={tabvalueO01} index={0} dir="x" className={classes.chart}>
          <TableContainer component={Paper} className={classes.TableContainer} >
          <Table className={classes.table} aria-label="key table">
          <TableHead>
          <TableRow>
          <TableCell>Key Num</TableCell>
          <TableCell align="right">Value</TableCell>
          <TableCell align="right">Score</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
          {rows.map((row) => (
          <TableRow key={row.name}>
          <TableCell component="th" scope="row">
          {row.name}
          </TableCell>
          <TableCell align="right">
          {
          row.name==="Trailing PE" && row.value>10 && row.value<40? 
          <p 
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}>{row.value}</p>
          : row.name==="Trailing PE" && row.value<0? 
          <p
          style={{
          color: "rgba(227, 59, 109,0.9)" ,
          }}
          >{row.value}</p>
          : 
          row.name==="Forward PE" && row.value>22 && row.value<40? 
          <p 
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}>{row.value}</p>
          : row.name==="Forward PE" && row.value<0? 
          <p
          style={{
          color: "rgba(227, 59, 109,0.9)" ,
          }}
          >{row.value}</p>
          : row.name==="PEG ratio (better <1)" && row.value<1.5 && row.value>0? 
          <p
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}
          >{row.value}</p> 
          : row.name==="Price To Book (better <3)" && row.value<3.75? 
          <p
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}
          >{row.value}</p>
          : row.name==="Short Ratio (8-10 is dangerious)" && row.value>10? 
          <p
          style={{
          color: "rgba(227, 59, 109,0.9)" ,
          }}
          >{row.value}</p>
          : row.name==="Dividend $" && row.value>0? 
          <p
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}
          >{row.value}</p>
          : row.name==="Gross Margin%" && row.rawValue>0.30? 
          <p
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}
          >{row.value}</p>
          : row.name==="Net Profit%" && row.rawValue>0.1? 
          <p
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}
          >{row.value}</p>
          : row.name==="ROE" && row.rawValue>0.1? 
          <p
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}
          >{row.value}</p>
          : row.name==="Price to Sales Ratio" && row.value<4 && row.value>2.5? 
          <p
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}
          >{row.value}</p>
          : row.name==="Current Ratio (>1)" && row.value>1? 
          <p
          style={{
          color: "rgba(6, 201, 42,0.9)" ,
          }}
          >{row.value}</p>
          : <p>{row.value}</p>
          }
                      
          </TableCell>
          {/* score */}
          <TableCell align="right">
          {row.score>0.5?<p 
          style={{
          color: "#ffa600" ,
          }}><StarsIcon/></p>
          : row.score<-0.5?<p
          style={{
          color: "rgba(227, 59, 109,0.9)" ,
          }}
          ><WarningIcon/></p>
          :<div></div>
          }


          </TableCell>

          </TableRow>
          ))}
          </TableBody>
          </Table>
          </TableContainer>
          </TabPanel>
          <TabPanel value={tabvalueO01} index={1} dir="x" className={classes.chart}>
          <div className={classes.chart}>
          <Radar data={radarData} 
          options={{
          scale: {
          ticks: { beginAtZero: true },
          },
          }} />
          </div>
          </TabPanel>
          </SwipeableViews>
          </div>           


          <div className={classes.tabs}>
            <AppBar position="static" color="default">
              <Tabs
                value={tabvalue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Quarterly" {...a11yProps(0)} />
                <Tab label="Yearly" {...a11yProps(1)} />
                <Tab label="Earning" {...a11yProps(2)} />

              </Tabs>
            </AppBar>

            <SwipeableViews
              axis="x"
              index={tabvalue}
              onChangeIndex={handleChangeIndex}
            >

              <TabPanel className={classes.chart} value={tabvalue} index={0} dir="x">
                  <Bar
                    data={barRevData}
                    height={280}
                    options={{

                      responsive: true,
                      maintainAspectRatio: false,

                      title: {
                        display: true,
                        text: "Quarterly Financial",
                        fontSize: 12,
                      }, 
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              maxRotation: 90,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
              </TabPanel>

              <TabPanel className={classes.chart} value={tabvalue} index={1} dir="x">
                  <Bar
                    data={barRevDataYearly}
                    height={280}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      title: {
                        display: true,
                        text: "Yearly Financial",
                        fontSize: 12,
                      },             
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              maxRotation: 90,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
              </TabPanel>
              <TabPanel className={classes.chart} value={tabvalue} index={2} dir="x">
                  <Bar
                    data={scatterEarningData}
                    height={280}
                    options={{
                      title: {
                        display: true,
                        text: "Earning Chart",
                        fontSize: 12,
                      },             
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              maxRotation: 90,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
              </TabPanel>
            </SwipeableViews>
          </div>

          {/* ownership */}
          <div className={classes.tabs}>
            <AppBar position="static" color="default">
              <Tabs
                value={tabvalueO}
                onChange={handleChangeO}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Ownership Perc" {...a11yProps(0)} />
                <Tab label="Ownership Institution" {...a11yProps(1)} />
                <Tab label="Ownership Funds" {...a11yProps(2)} />
                

              </Tabs>
            </AppBar>
            <SwipeableViews
              axis="x"
              index={tabvalueO}
              onChangeIndex={handleChangeIndexO}
            >
              <TabPanel value={tabvalueO} index={0} dir="x" className={classes.chart}>
                  <Doughnut
                    data={ownershipData}
                    height={280}
                    options={{
                      maintainAspectRatio: false,
                      title: {
                        display: true,
                        text: "Ownership",
                        fontSize: 12,
                      },
                      legend: {
                        display: false,
                        // position: "right",
                      },
                    }}
                  />
              </TabPanel>

              <TabPanel value={tabvalueO} index={1} dir="x" className={classes.chart}>
                  <Bar
                    data={barOwnerInsData}
                    height={280}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              maxRotation: 90,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />

              </TabPanel>

              <TabPanel value={tabvalueO} index={2} dir="x" className={classes.chart}>
                  <Bar
                    data={barOwnerData}
                    height={280}

                    options={{
                      responsive: true,

                      maintainAspectRatio: false,
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              maxRotation: 90,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
              </TabPanel>
            </SwipeableViews>
          </div>

          <div className={classes.chart}>
            <Bar
              data={barRecData}
              // width={100}
              height={250}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        maxRotation: 90,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              title: {
                display: true,
                text: "Analysts' Opinon"
              }
              }}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      


        <div>

        {Keyword? (<h6><DescriptionIcon />&nbsp;{Keyword} News</h6>):<div></div>}

        {dataNews.map(function(d){return (
        <div className="list-group bg-transparent" key={d.uuid}>    
        <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent" key={d.uuid}>
        <div>
        <div><a href={d.link} target="blank"><h6>{d.title}</h6></a></div>
        <div><CalendarTodayIcon style={{margin:5}}/>{d.providerPublishTime_converted}</div>   
        {/* <p>{d.description}</p> */}
        {/* <img alt={d.urlToImage} src={d.urlToImage} className="img-thumbnail text-left"/> */}
        </div>
        <span className="badge badge-primary badge-pill">{d.publisher}</span>
        </li>
        </div>
        )})}

        </div>

       
        
        {Keyword? (<div> <Divider /><h6 className={classes.tabs}><ForumIcon/> Company </h6>
        <Typography>
          {longSummary}
        </Typography></div>):<div></div>}
        


      <div>
      {myApidata.length>0 || yahooChat.length>0?(
      <div>
      <h6 className={classes.tabs}><ForumIcon/> Conversation </h6></div>
      ):<div></div>}
      {/* <PostComment/> */}
        {/* {postiveSentiment} */}
        {/* {negativeSentiment}
        {neutralSentiment} */}
      {myApidata.map((mycomment) => (
      <List dense={false} key={mycomment._id}>
      <ListItem alignItems="flex-start">
        
      <ListItemText primary={mycomment.Description} secondary={
        <React.Fragment>
        <Typography
          component="span"
          variant="body2"
          className={classes.inline}
          color="textPrimary"
        >
          {"User: "}
          {mycomment.user_name}
        </Typography>
        <Typography>
         {mycomment.comment_name}
         </Typography>
         <Typography>
         {mycomment.sentimentScore}
         </Typography>
         <Typography>
           {" Date: "}
        {mycomment.updatedAt}
        </Typography>
      </React.Fragment>        
      }/>
      </ListItem>
      <Divider variant="inset" component="li" />
      </List>
      ))}
      

        {yahooChat.map((chat) => (
         <List dense={false} key={chat.messageId}>
         <ListItem alignItems="flex-start">
           
         <ListItemText primary={chat.label} secondary={
           <Typography>
            <Typography
            component="span"
            variant="body2"
            className={classes.inline}
            color="textPrimary"
            >
            {"User: "}
            {chat.nickName}
            </Typography>
            <Typography>
            {chat.detailUserText}
            </Typography>
            <Typography>
            {
            chat.sentimentScore>0.65? (
            <span style={{
              color: "rgba(6, 201, 42,0.9)" ,
            }}>{chat.sentimentScore.toFixed(4)} &nbsp;Positive</span>):
            chat.sentimentScore<0.35? (<span style={{
              color: "rgba(227, 59, 109,0.9)",
            }}>{chat.sentimentScore.toFixed(4)} &nbsp;Negative</span>)
            :(<span>{chat.sentimentScore.toFixed(4)} &nbsp;Neutral</span>)
            }
            </Typography>
            <Typography component={'span'} variant={'body2'}>
            {" Date: "}
            {chat.providerPublishTime_converted}
            </Typography>
            <Typography component={'div'} variant={'body2'}>
            <ThumbUpIcon color="primary"/>&nbsp;{chat.reactUp} &nbsp;&nbsp;
            <ThumbDownIcon color="secondary"/>&nbsp;{chat.reactDown}
            </Typography>
           
         </Typography> 
           }/>
          
         </ListItem>
         <Divider variant="inset" component="li" />
         </List>
         ))}
        
        {myApidata.length>0 || yahooChat.length>0?(
        <div style={{textAlign: 'center', margin:10}}>
        <Button onClick={loadMore} variant="contained" >
            {isLoading ? 'Loading...' : 'Load More'}
        </Button>
        {errorMsg}
        </div>
        ):<div></div>}

        </div>


    </div>
  );
}
