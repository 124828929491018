import React from 'react';
import FetchWeatherApi from "./components/FetchWeatherApi.jsx";
import "./css/Home.css";
import WbSunnyIcon from '@mui/icons-material/WbSunny';


function Weather() {

  


  return (
    
    <div>
      <h3><WbSunnyIcon/> Weather</h3>
    <FetchWeatherApi/>

    </div>
  );
}

export default Weather;