
import { createTheme } from "@mui/material/styles";


export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  background: '#999',
});

export const lightTheme = createTheme({
  palette: {
    mode: "light"
  }
});


// export const lightTheme = {
//     body: '#FFF',
//     text: '#363537',
//     toggleBorder: '#FFF',
//     background: '#363537',
    
       
// }
// export const darkTheme = {
//     body: '#363537',
//     text: '#FAFAFA',
//     toggleBorder: '#6B8096',
//     background: '#999',
//     }

