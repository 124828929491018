// https://www.digitalocean.com/community/tutorials/react-axios-react

import React from 'react';
// import Iframe from 'react-iframe'
// import brandpicture from '../public/brand.png'; 
import "./css/Home.css";
import AssessmentIcon from '@mui/icons-material/Assessment';

import BarChart from './components/BarChart';
// import Container from '@mui/material/Container';

function Charts() {

  return (
    
    <div>
      <h3><AssessmentIcon/> Statistics</h3>
      {/* <img src={brandpicture} alt="brand" /> */}

      <BarChart />

      </div>
  );
}

export default Charts;
