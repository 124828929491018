import React from "react";
import axios from 'axios';

import hot from '../../public/hot.png';
import warm from '../../public/warm.png';
import cool from '../../public/cool.png';
import cold from '../../public/cold.png';
import freeze from '../../public/freeze.png';


import WarningIcon from '@mui/icons-material/Warning';

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';


import { Line, Bar } from 'react-chartjs-2';
// import { withStyles } from '@mui/material/styles';

import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';


import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { MapContainer, TileLayer, Marker, Popup,useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import cities_json_file from "../data/mycitycountry.json";


const darkskyAPIkey= process.env.REACT_APP_DARKSKYAPIKEY;
const bigdatacloudAPIkey= process.env.REACT_APP_BIGDATACLOUDAPIKEY;
// const openweatherAPIkey= process.env.REACT_APP_OPENWEATHER

const theme = createTheme({
  chartarea: {
    display: 'flex',
    position: "relative",
    marginTop: 25,
    minHeight: 280,
    maxHeight: 420,
  },

  grid: {
    marginTop: 6,
    marginBottom: 6,
    flexGrow: 1,
  },
});

function capitalizeFirstLetter(string) {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  catch (err) {
    return null;
  }
}

function EpochToDate(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var time = year + '-' + month + '-' + date;
  return time;
}
function ChangeView({ center }) {
  const map = useMap();
  map.setView(center);
  return null;
}

const cities_json=cities_json_file["coordinations"]//{"Tokyo, Japan":[48.8566, 2.3522], "Jakarta, Indonesia":[51.5072, -0.1276], "Delhi, India":[43.6532, -79.3832], "Manila, Philippines":[25.7617, -80.1918]};
const cities = Object.keys(cities_json) //["Paris", "London", "Toronto"] 

const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();
const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};
export default class FetchWeatherApi extends React.Component {

  
  constructor(props) {
    super(props);
    
    this.state = {
      stockList: [],
      currentCoordinate:[15.5689, -79.564658],
      cityname: null,
      principalSubdivision:null,
      countryName:null,

      condition: null,
      icon: null,
      temperature: "",
      day0name: null,
      day1name: null,
      day2name: null,
      day3name: null,
      day4name: null,

      day0WkName: null,
      day1WkName: null,
      day2WkName: null,
      day3WkName: null,
      day4WkName: null,

      day0summary: "",
      day1summary: "",
      day2summary: "",
      day3summary: "",
      day4summary: "",

      day0apparentMax: null,
      day1apparentMax: null,
      day2apparentMax: null,
      day3apparentMax: null,
      day4apparentMax: null,

      day0apparentMin: null,
      day1apparentMin: null,
      day2apparentMin: null,
      day3apparentMin: null,
      day4apparentMin: null,

      day0PrecipType: null,
      day1PrecipType: null,
      day2PrecipType: null,
      day3PrecipType: null,
      day4PrecipType: null,

      day0PrecipProbability: null,
      day1PrecipProbability: null,
      day2PrecipProbability: null,
      day3PrecipProbability: null,
      day4PrecipProbability: null,

      alertMsgList: [],

      lineHistData: {},
      barChartdata: {},
      sunLineData: {},
      loading: false,

    }
  }


  weatherInit = () => {

    let weatherDate = [];
    let weathertempMax = [];
    let weathertempMin = [];
    let alertMsg = [];
    let windValue = [];


    let day1SunGL = 0;
    let day2SunGL = 0;
    let day3SunGL = 0;
    let day4SunGL = 0;
    let day5SunGL = 0;
    let day6SunGL = 0;
    let day7SunGL = 0;

    // let latitude=30.2672
    // let longitude=-97.7431


    navigator.geolocation.getCurrentPosition(function (position) {

      // const weatherurl = 'https://dark-sky.p.rapidapi.com/' + position.coords.latitude + ',' + position.coords.longitude;
      // const weatherurl = 'https://dark-sky.p.rapidapi.com/'+ latitude + ',' + longitude;

      const locationurl= 'https://api.bigdatacloud.net/data/reverse-geocode-client?latitude='+ position.coords.latitude +'&longitude='+ position.coords.longitude +'&localityLanguage=en&key='+bigdatacloudAPIkey;
      // const locationurl= 'https://api.bigdatacloud.net/data/reverse-geocode-client?latitude='+ latitude +'&longitude='+ longitude +'&localityLanguage=en&key='+bigdatacloudAPIkey;

      const weatheroptions = {
        method: 'GET',
        url: 'https://api.openweathermap.org/data/3.0/onecall',
        params: {
          lat: position.coords.latitude,
          lon: position.coords.longitude,
          exclude: 'minutely,hourly',
          appid: darkskyAPIkey,
        },
      };

      axios.request(locationurl).then(function (response) {
        currentComponent.setState({
          currentCoordinate: [position.coords.latitude,  position.coords.longitude],
          cityname:response.data.city,
          principalSubdivision:response.data.principalSubdivision,
          countryName:response.data.countryName
        })
      
      }).catch(function(err) {console.error(err);});
      
      
      axios.request(weatheroptions).then(function (response) {
        console.log("get weather data: ", response.data)
        // console.log(response.data.flags.units)
        // var unit=response.data.flags.units
        response.data.daily.forEach((item) => {
          if (item) { //remove null description
            weatherDate.push(EpochToDate(item.dt));            
            weathertempMax.push(Math.round(item.temp.max-273.15));
            weathertempMin.push(Math.round(item.temp.min-273.15));
            windValue.push(Math.round(item.wind_speed));
          }
        })


        day1SunGL = ((response.data.daily[1].sunset - response.data.daily[1].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day2SunGL = ((response.data.daily[2].sunset - response.data.daily[2].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day3SunGL = ((response.data.daily[3].sunset - response.data.daily[3].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day4SunGL = ((response.data.daily[4].sunset - response.data.daily[4].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day5SunGL = ((response.data.daily[5].sunset - response.data.daily[5].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day6SunGL = ((response.data.daily[6].sunset - response.data.daily[6].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day7SunGL = ((response.data.daily[7].sunset - response.data.daily[7].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;


        // if (response.data.alerts) {
        //   response.data.alerts.forEach((item) => {
        //     if (item) { //remove null description
        //       alertMsg.push(item.description);
        //     }
        //   })
        // }

        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
 
        currentComponent.setState({
          condition: response.data.current.weather[0].description,
          icon: response.data.current.weather[0].icon,
          temperature: Math.round(response.data.current.temp-273.15),

          day0name: EpochToDate(response.data.daily[0].dt),
          day1name: EpochToDate(response.data.daily[1].dt),
          day2name: EpochToDate(response.data.daily[2].dt),
          day3name: EpochToDate(response.data.daily[3].dt),
          day4name: EpochToDate(response.data.daily[4].dt),

          day0WkName: days[new Date(EpochToDate(response.data.daily[0].dt)).getDay()],
          day1WkName: days[new Date(EpochToDate(response.data.daily[1].dt)).getDay()],
          day2WkName: days[new Date(EpochToDate(response.data.daily[2].dt)).getDay()],
          day3WkName: days[new Date(EpochToDate(response.data.daily[3].dt)).getDay()],
          day4WkName: days[new Date(EpochToDate(response.data.daily[4].dt)).getDay()],

          day0summary: response.data.daily[0].summary,
          day1summary: response.data.daily[1].summary,
          day2summary: response.data.daily[2].summary,
          day3summary: response.data.daily[3].summary,
          day4summary: response.data.daily[4].summary,

          day0apparentMax: Math.round(response.data.daily[0].feels_like.day-273.15),
          day1apparentMax: Math.round(response.data.daily[1].feels_like.day-273.15),
          day2apparentMax: Math.round(response.data.daily[2].feels_like.day-273.15),
          day3apparentMax: Math.round(response.data.daily[3].feels_like.day-273.15),
          day4apparentMax: Math.round(response.data.daily[4].feels_like.day-273.15),

          day0apparentMin: Math.round(response.data.daily[0].feels_like.night-273.15),
          day1apparentMin: Math.round(response.data.daily[1].feels_like.night-273.15),
          day2apparentMin: Math.round(response.data.daily[2].feels_like.night-273.15),
          day3apparentMin: Math.round(response.data.daily[3].feels_like.night-273.15),
          day4apparentMin: Math.round(response.data.daily[4].feels_like.night-273.15),

          day0PrecipType: capitalizeFirstLetter(response.data.daily[0].weather[0].main),
          day1PrecipType: capitalizeFirstLetter(response.data.daily[1].weather[0].main),
          day2PrecipType: capitalizeFirstLetter(response.data.daily[2].weather[0].main),
          day3PrecipType: capitalizeFirstLetter(response.data.daily[3].weather[0].main),
          day4PrecipType: capitalizeFirstLetter(response.data.daily[4].weather[0].main),


          day0PrecipProbability: response.data.daily[0].rain,
          day1PrecipProbability: response.data.daily[1].rain,
          day2PrecipProbability: response.data.daily[2].rain,
          day3PrecipProbability: response.data.daily[3].rain,
          day4PrecipProbability: response.data.daily[4].rain,


          alertMsgList: alertMsg,

          lineHistData: {
            labels: weatherDate, // lineChartDate, histDate.reverse(),
            datasets: [
              {
                label: "Max Temperature",
                data: weathertempMax, //.reverse(),
                fill: true,
                borderColor: "#3b9633",
              },
              {
                label: "Min Temperature",
                data: weathertempMin,
                fill: true,
                borderColor: "#338796",
              }
            ],
          },

          barChartdata: {
            labels: weatherDate,
            datasets: [{
              label: 'KM/H',
              backgroundColor: 'rgba(12, 200, 109,0.2)',
              borderColor: 'rgba(12, 200, 109,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(12, 200, 109,0.4)',
              hoverBorderColor: 'rgba(12, 200, 109,1)',
              data: windValue
            }]
          },

          sunLineData: {
            labels: weatherDate,
            datasets: [
              {
                label: "Sun Time Gain/Loss (Minutes)",
                data: [0, day1SunGL, day2SunGL, day3SunGL, day4SunGL, day5SunGL, day6SunGL, day7SunGL],
                fill: true,
                borderColor: "#FFC75F",
              }
            ],
          },

        }) 
      

      
        
      
      
      }).catch(function (error) {
        console.error(error);
      });
    

    });
    
    let currentComponent = this;

  }


  componentDidMount() {
    this.weatherInit()
  }

  addMarker = (e) => {
    const { markers } = this.state;
    markers.pop();
    markers.push(e.latlng);
    this.setState({ markers });
  }


 searchCity=(e) => {
    this.setState({ loading: true });
    // Simulate an async operation
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);


    e.preventDefault();
    var mycity = document.getElementById("cities_list").value;
    // eslint-disable-next-line
  
    // console.log(mycity)
    // console.log(cities_json_file["coordinations"][mycity])
  
  
      let weatherDate = [];
      let weathertempMax = [];
      let weathertempMin = [];
      let alertMsg = [];
      let windValue = [];
    
    
      let day1SunGL = 0;
      let day2SunGL = 0;
      let day3SunGL = 0;
      let day4SunGL = 0;
      let day5SunGL = 0;
      let day6SunGL = 0;
      let day7SunGL = 0;
    
    
      // const weatherurl = 'https://dark-sky.p.rapidapi.com/' +  cities_json[mycity][0] + ',' + cities_json[mycity][1];
      const locationurl= 'https://api.bigdatacloud.net/data/reverse-geocode-client?latitude='+ cities_json[mycity][0] +'&longitude='+ cities_json[mycity][1] +'&localityLanguage=en&key='+bigdatacloudAPIkey
    
      const weatheroptions = {
        method: 'GET',
        url: 'https://api.openweathermap.org/data/3.0/onecall',
        params: {
          lat: cities_json[mycity][0],
          lon: cities_json[mycity][1],
          exclude: 'minutely,hourly',
          appid: darkskyAPIkey,
        },
      };
    
        axios.request(locationurl).then(function (response) {
          currentComponent.setState({
            currentCoordinate: [cities_json[mycity][0],  cities_json[mycity][1]],
            cityname:response.data.city,
            principalSubdivision:response.data.principalSubdivision,
            countryName:response.data.countryName
          })
        
        }).catch(function(err) {console.error(err);});
        
        
        axios.request(weatheroptions).then(function (response) {
          console.log("get weather data: ", response.data)
          // console.log(response.data.flags.units)
          // var unit=response.data.flags.units
          response.data.daily.forEach((item) => {
            if (item) { //remove null description
              weatherDate.push(EpochToDate(item.dt));
              weathertempMax.push(Math.round(item.temp.max-273.15));
              weathertempMin.push(Math.round(item.temp.min-273.15));
              windValue.push(Math.round(item.wind_speed));
            }
          })
    
    
        day1SunGL = ((response.data.daily[1].sunset - response.data.daily[1].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day2SunGL = ((response.data.daily[2].sunset - response.data.daily[2].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day3SunGL = ((response.data.daily[3].sunset - response.data.daily[3].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day4SunGL = ((response.data.daily[4].sunset - response.data.daily[4].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day5SunGL = ((response.data.daily[5].sunset - response.data.daily[5].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day6SunGL = ((response.data.daily[6].sunset - response.data.daily[6].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;
        day7SunGL = ((response.data.daily[7].sunset - response.data.daily[7].sunrise) - (response.data.daily[0].sunset - response.data.daily[0].sunrise)) / 60;

    
          // if (response.data.alerts) {
          //   response.data.alerts.forEach((item) => {
          //     if (item) { //remove null description
          //       alertMsg.push(item.description);
          //     }
          //   })
          // }
    
          var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
 
          currentComponent.setState({
            condition: response.data.current.weather[0].description,
            icon: response.data.current.weather[0].icon,
            temperature: Math.round(response.data.current.temp-273.15),
    
            day0name: EpochToDate(response.data.daily[0].dt),
            day1name: EpochToDate(response.data.daily[1].dt),
            day2name: EpochToDate(response.data.daily[2].dt),
            day3name: EpochToDate(response.data.daily[3].dt),
            day4name: EpochToDate(response.data.daily[4].dt),
    
            day0WkName: days[new Date(EpochToDate(response.data.daily[0].dt)).getDay()],
            day1WkName: days[new Date(EpochToDate(response.data.daily[1].dt)).getDay()],
            day2WkName: days[new Date(EpochToDate(response.data.daily[2].dt)).getDay()],
            day3WkName: days[new Date(EpochToDate(response.data.daily[3].dt)).getDay()],
            day4WkName: days[new Date(EpochToDate(response.data.daily[4].dt)).getDay()],


            day0summary: response.data.daily[0].summary,
            day1summary: response.data.daily[1].summary,
            day2summary: response.data.daily[2].summary,
            day3summary: response.data.daily[3].summary,
            day4summary: response.data.daily[4].summary,

            day0apparentMax: Math.round(response.data.daily[0].feels_like.day-273.15),
            day1apparentMax: Math.round(response.data.daily[1].feels_like.day-273.15),
            day2apparentMax: Math.round(response.data.daily[2].feels_like.day-273.15),
            day3apparentMax: Math.round(response.data.daily[3].feels_like.day-273.15),
            day4apparentMax: Math.round(response.data.daily[4].feels_like.day-273.15),

            day0apparentMin: Math.round(response.data.daily[0].feels_like.night-273.15),
            day1apparentMin: Math.round(response.data.daily[1].feels_like.night-273.15),
            day2apparentMin: Math.round(response.data.daily[2].feels_like.night-273.15),
            day3apparentMin: Math.round(response.data.daily[3].feels_like.night-273.15),
            day4apparentMin: Math.round(response.data.daily[4].feels_like.night-273.15),

            day0PrecipType: capitalizeFirstLetter(response.data.daily[0].weather[0].main),
            day1PrecipType: capitalizeFirstLetter(response.data.daily[1].weather[0].main),
            day2PrecipType: capitalizeFirstLetter(response.data.daily[2].weather[0].main),
            day3PrecipType: capitalizeFirstLetter(response.data.daily[3].weather[0].main),
            day4PrecipType: capitalizeFirstLetter(response.data.daily[4].weather[0].main),


            day0PrecipProbability: response.data.daily[0].rain,
            day1PrecipProbability: response.data.daily[1].rain,
            day2PrecipProbability: response.data.daily[2].rain,
            day3PrecipProbability: response.data.daily[3].rain,
            day4PrecipProbability: response.data.daily[4].rain,
    
    
            alertMsgList: alertMsg,
    
            lineHistData: {
              labels: weatherDate, // lineChartDate, histDate.reverse(),
              datasets: [
                {
                  label: "Max Temperature",
                  data: weathertempMax, //.reverse(),
                  fill: true,
                  borderColor: "#3b9633",
                },
                {
                  label: "Min Temperature",
                  data: weathertempMin,
                  fill: true,
                  borderColor: "#338796",
                }
              ],
            },
    
            barChartdata: {
              labels: weatherDate,
              datasets: [{
                label: 'KM/H',
                backgroundColor: 'rgba(12, 200, 109,0.2)',
                borderColor: 'rgba(12, 200, 109,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(12, 200, 109,0.4)',
                hoverBorderColor: 'rgba(12, 200, 109,1)',
                data: windValue
              }]
            },
    
            sunLineData: {
              labels: weatherDate,
              datasets: [
                {
                  label: "Sun Time Gain/Loss (Minutes)",
                  data: [0, day1SunGL, day2SunGL, day3SunGL, day4SunGL, day5SunGL, day6SunGL, day7SunGL],
                  fill: true,
                  borderColor: "#FFC75F",
                }
              ],
            },
    
          }) 
        
    
        
           
        }).catch(function (error) {
          console.error(error);
        });
      
  
      let currentComponent = this;
    };


  render() {
    const { loading } = this.state;
    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow
    });
    L.Marker.prototype.options.icon = DefaultIcon;
    return (
      <ThemeProvider  theme={theme}>

      <div>
      <Autocomplete
      disablePortal
      id="cities_list"
      filterOptions={filterOptions}
      options={cities}
      sx={{ width: 400, marginBottom:1 }}
      renderInput={(params) => <TextField {...params} label="City" />}
      />



          <Button 
          variant="contained" 
          color="primary" 
          style={{ marginBottom: 12 }} 
          onClick={this.searchCity} 
          disabled={loading}
          >
          {loading ? 
          <CircularProgress size={24} /> 
          : 
          <>
          <SearchIcon />
          Search
          </>
          }
          </Button>

        {this.state.temperature != null ?
          <div>
            <span><h4>{this.state.cityname}</h4> <p>{this.state.principalSubdivision}, {this.state.countryName}</p></span>
            <h2>{this.state.condition}</h2>
            <h2>{this.state.temperature} °C</h2>

            <Grid container className={theme.grid} spacing={2} direction="row">
              <Grid item xs={8} sm={6} md={3} lg={2} xl={2}>
                {(() => {
                  if (this.state.day0apparentMax >= 25) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day0name} {this.state.day0WkName} :  <br></br> Feel Like:  <b> {this.state.day0apparentMin} °C ~ {this.state.day0apparentMax}°C </b><br></br> {this.state.day0summary}<br></br>{this.state.day0PrecipType}: {this.state.day0PrecipProbability}mm
                        <img src={hot} alt="hot" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day0apparentMax >= 10 && this.state.day0apparentMax < 25) {
                    return (
                      <Box bgcolor="#37cdbe" color="success.contrastText" p={2}>
                        {this.state.day0name} {this.state.day0WkName} :  <br></br> Feel Like:  <b> {this.state.day0apparentMin} °C ~ {this.state.day0apparentMax}°C </b><br></br> {this.state.day0summary}<br></br>{this.state.day0PrecipType}: {this.state.day0PrecipProbability}mm
                        <img src={warm} alt="warm" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day0apparentMax >= 0 && this.state.day0apparentMax < 10) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day0name} {this.state.day0WkName} :  <br></br> Feel Like:  <b> {this.state.day0apparentMin} °C ~ {this.state.day0apparentMax}°C </b><br></br> {this.state.day0summary}<br></br>{this.state.day0PrecipType}: {this.state.day0PrecipProbability}mm
                        <img src={cool} alt="cool" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day0apparentMax > -10 && this.state.day0apparentMax < 0) {
                    return (
                      <Box bgcolor="#49C2C7" color="success.contrastText" p={2}>
                        {this.state.day0name} {this.state.day0WkName} :  <br></br> Feel Like:  <b> {this.state.day0apparentMin} °C ~ {this.state.day0apparentMax}°C </b><br></br> {this.state.day0summary}<br></br>{this.state.day0PrecipType}: {this.state.day0PrecipProbability}mm
                        <img src={cold} alt="cold" style={{ width: "60" }} />
                      </Box>
                    )
                  } else {
                    return (
                      <Box bgcolor="#5EACC2" color="success.contrastText" p={2}>
                        {this.state.day0name} {this.state.day0WkName} :  <br></br> Feel Like:  <b> {this.state.day0apparentMin} °C ~ {this.state.day0apparentMax}°C </b><br></br> {this.state.day0summary}<br></br>{this.state.day0PrecipType}: {this.state.day0PrecipProbability}mm
                        <img src={freeze} alt="freeze" style={{ width: "60" }} />
                      </Box>
                    )
                  }
                })()}
              </Grid>


              <Grid item xs={8} sm={6} md={3} lg={2} xl={2}>
                {(() => {
                  if (this.state.day1apparentMax >= 25) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day1name} {this.state.day1WkName} :  <br></br> Feel Like:  <b> {this.state.day1apparentMin} °C ~ {this.state.day1apparentMax}°C </b><br></br> {this.state.day1summary}<br></br>{this.state.day1PrecipType}: {this.state.day1PrecipProbability}mm
                        <img src={hot} alt="hot" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day1apparentMax >= 10 && this.state.day1apparentMax < 25) {
                    return (
                      <Box bgcolor="#37cdbe" color="success.contrastText" p={2}>
                        {this.state.day1name} {this.state.day1WkName} :  <br></br> Feel Like:  <b> {this.state.day1apparentMin} °C ~ {this.state.day1apparentMax}°C </b><br></br> {this.state.day1summary}<br></br>{this.state.day1PrecipType}: {this.state.day1PrecipProbability}mm
                        <img src={warm} alt="warm" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day1apparentMax >= 0 && this.state.day1apparentMax < 10) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day1name} {this.state.day1WkName} :  <br></br> Feel Like:  <b> {this.state.day1apparentMin} °C ~ {this.state.day1apparentMax}°C </b><br></br> {this.state.day1summary}<br></br>{this.state.day1PrecipType}: {this.state.day1PrecipProbability}mm
                        <img src={cool} alt="cool" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day1apparentMax > -10 && this.state.day1apparentMax < 0) {
                    return (
                      <Box bgcolor="#49C2C7" color="success.contrastText" p={2}>
                        {this.state.day1name} {this.state.day1WkName} :  <br></br> Feel Like:  <b> {this.state.day1apparentMin} °C ~ {this.state.day1apparentMax}°C </b><br></br> {this.state.day1summary}<br></br>{this.state.day1PrecipType}: {this.state.day1PrecipProbability}mm
                        <img src={cold} alt="cold" style={{ width: "60" }} />
                      </Box>
                    )
                  } else {
                    return (
                      <Box bgcolor="#5EACC2" color="success.contrastText" p={2}>
                        {this.state.day1name} {this.state.day1WkName} :  <br></br> Feel Like:  <b> {this.state.day1apparentMin} °C ~ {this.state.day1apparentMax}°C </b><br></br> {this.state.day1summary}<br></br>{this.state.day1PrecipType}: {this.state.day1PrecipProbability}mm
                        <img src={freeze} alt="freeze" style={{ width: "60" }} />
                      </Box>
                    )
                  }
                })()}
              </Grid>

              <Grid item xs={8} sm={6} md={3} lg={2} xl={2}>
                {(() => {
                  if (this.state.day2apparentMax >= 25) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day2name} {this.state.day2WkName} :  <br></br> Feel Like:  <b> {this.state.day2apparentMin} °C ~ {this.state.day2apparentMax}°C </b><br></br> {this.state.day2summary}<br></br>{this.state.day2PrecipType}: {this.state.day2PrecipProbability}mm
                        <img src={hot} alt="hot" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day2apparentMax >= 10 && this.state.day2apparentMax < 25) {
                    return (
                      <Box bgcolor="#37cdbe" color="success.contrastText" p={2}>
                        {this.state.day2name} {this.state.day2WkName} :  <br></br> Feel Like:  <b> {this.state.day2apparentMin} °C ~ {this.state.day2apparentMax}°C </b><br></br> {this.state.day2summary}<br></br>{this.state.day2PrecipType}: {this.state.day2PrecipProbability}mm
                        <img src={warm} alt="warm" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day2apparentMax >= 0 && this.state.day2apparentMax < 10) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day2name} {this.state.day2WkName} :  <br></br> Feel Like:  <b> {this.state.day2apparentMin} °C ~ {this.state.day2apparentMax}°C </b><br></br> {this.state.day2summary}<br></br>{this.state.day2PrecipType}: {this.state.day2PrecipProbability}mm
                        <img src={cool} alt="cool" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day2apparentMax > -10 && this.state.day2apparentMax < 0) {
                    return (
                      <Box bgcolor="#49C2C7" color="success.contrastText" p={2}>
                        {this.state.day2name} {this.state.day2WkName} :  <br></br> Feel Like:  <b> {this.state.day2apparentMin} °C ~ {this.state.day2apparentMax}°C </b><br></br> {this.state.day2summary}<br></br>{this.state.day2PrecipType}: {this.state.day2PrecipProbability}mm
                        <img src={cold} alt="cold" style={{ width: "60" }} />
                      </Box>
                    )
                  } else {
                    return (
                      <Box bgcolor="#5EACC2" color="success.contrastText" p={2}>
                        {this.state.day2name} {this.state.day2WkName} :  <br></br> Feel Like:  <b> {this.state.day2apparentMin} °C ~ {this.state.day2apparentMax}°C </b><br></br> {this.state.day2summary}<br></br>{this.state.day2PrecipType}: {this.state.day2PrecipProbability}mm
                        <img src={freeze} alt="freeze" style={{ width: "60" }} />
                      </Box>
                    )
                  }
                })()}
              </Grid>

              <Grid item xs={8} sm={6} md={3} lg={2} xl={2}>
                {(() => {
                  if (this.state.day3apparentMax >= 25) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day3name} {this.state.day3WkName} :  <br></br> Feel Like:  <b> {this.state.day3apparentMin} °C ~ {this.state.day3apparentMax}°C </b><br></br> {this.state.day3summary}<br></br>{this.state.day3PrecipType}: {this.state.day3PrecipProbability}mm
                        <img src={hot} alt="hot" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day3apparentMax >= 10 && this.state.day3apparentMax < 25) {
                    return (
                      <Box bgcolor="#37cdbe" color="success.contrastText" p={2}>
                        {this.state.day3name} {this.state.day3WkName} :  <br></br> Feel Like:  <b> {this.state.day3apparentMin} °C ~ {this.state.day3apparentMax}°C </b><br></br> {this.state.day3summary}<br></br>{this.state.day3PrecipType}: {this.state.day3PrecipProbability}mm
                        <img src={warm} alt="warm" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day3apparentMax >= 0 && this.state.day3apparentMax < 10) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day3name} {this.state.day3WkName} :  <br></br> Feel Like:  <b> {this.state.day3apparentMin} °C ~ {this.state.day3apparentMax}°C </b><br></br> {this.state.day3summary}<br></br>{this.state.day3PrecipType}: {this.state.day3PrecipProbability}mm
                        <img src={cool} alt="cool" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day3apparentMax > -10 && this.state.day3apparentMax < 0) {
                    return (
                      <Box bgcolor="#49C2C7" color="success.contrastText" p={2}>
                        {this.state.day3name} {this.state.day3WkName} :  <br></br> Feel Like:  <b> {this.state.day3apparentMin} °C ~ {this.state.day3apparentMax}°C </b><br></br> {this.state.day3summary}<br></br>{this.state.day3PrecipType}: {this.state.day3PrecipProbability}mm
                        <img src={cold} alt="cold" style={{ width: "60" }} />
                      </Box>
                    )
                  } else {
                    return (
                      <Box bgcolor="#5EACC2" color="success.contrastText" p={2}>
                        {this.state.day3name} {this.state.day3WkName} :  <br></br> Feel Like:  <b> {this.state.day3apparentMin} °C ~ {this.state.day3apparentMax}°C </b><br></br> {this.state.day3summary}<br></br>{this.state.day3PrecipType}: {this.state.day3PrecipProbability}mm
                        <img src={freeze} alt="freeze" style={{ width: "60" }} />
                      </Box>
                    )
                  }
                })()}
              </Grid>
              <Grid item xs={8} sm={6} md={3} lg={2} xl={2}>
                {(() => {
                  if (this.state.day4apparentMax >= 25) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day4name} {this.state.day4WkName} :  <br></br> Feel Like:  <b> {this.state.day4apparentMin} °C ~ {this.state.day4apparentMax}°C </b><br></br> {this.state.day4summary}<br></br>{this.state.day4PrecipType}: {this.state.day4PrecipProbability}mm
                        <img src={hot} alt="hot" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day4apparentMax >= 10 && this.state.day4apparentMax < 25) {
                    return (
                      <Box bgcolor="#37cdbe" color="success.contrastText" p={2}>
                        {this.state.day4name} {this.state.day4WkName} :  <br></br> Feel Like:  <b> {this.state.day4apparentMin} °C ~ {this.state.day4apparentMax}°C </b><br></br> {this.state.day4summary}<br></br>{this.state.day4PrecipType}: {this.state.day4PrecipProbability}mm
                        <img src={warm} alt="warm" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day4apparentMax >= 0 && this.state.day4apparentMax < 10) {
                    return (
                      <Box bgcolor="#a991f7" color="success.contrastText" p={2}>
                        {this.state.day4name} {this.state.day4WkName} :  <br></br> Feel Like:  <b> {this.state.day4apparentMin} °C ~ {this.state.day4apparentMax}°C </b><br></br> {this.state.day4summary}<br></br>{this.state.day4PrecipType}: {this.state.day4PrecipProbability}mm
                        <img src={cool} alt="cool" style={{ width: "60" }} />
                      </Box>
                    )
                  } else if (this.state.day4apparentMax > -10 && this.state.day4apparentMax < 0) {
                    return (
                      <Box bgcolor="#49C2C7" color="success.contrastText" p={2}>
                        {this.state.day4name} {this.state.day4WkName} :  <br></br> Feel Like:  <b> {this.state.day4apparentMin} °C ~ {this.state.day4apparentMax}°C </b><br></br> {this.state.day4summary}<br></br>{this.state.day4PrecipType}: {this.state.day4PrecipProbability}mm
                        <img src={cold} alt="cold" style={{ width: "60" }} />
                      </Box>
                    )
                  } else {
                    return (
                      <Box bgcolor="#5EACC2" color="success.contrastText" p={2}>
                        {this.state.day4name} {this.state.day4WkName} :  <br></br> Feel Like:  <b> {this.state.day4apparentMin} °C ~ {this.state.day4apparentMax}°C </b><br></br> {this.state.day4summary}<br></br>{this.state.day4PrecipType}: {this.state.day4PrecipProbability}mm
                        <img src={freeze} alt="freeze" style={{ width: "60" }} />
                      </Box>
                    )
                  }
                })()}
              </Grid>
            </Grid>


          </div>
          : <div></div>
        }
        <br></br>
       {this.state.alertMsgList.map((msg, index) =>
        (
          <p key={index}><WarningIcon />{msg}</p>
        )
        )}

        <div className={theme.chartarea}>
          <Line data={this.state.lineHistData}
            height={280}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              title: {
                display: true,
                text: "Temperature",
                fontSize: 12,
              },
              point: {
                radius: 0
              },
              tooltips: {
                mode: 'label'
              },
              hover: {
                mode: 'dataset'
              },
              scales: {
                xAxes: [
                  {
                    type: "time",
                    time: { unit: "day", displayFormats: { day: "YYYY-MM-DD" } },
                  },
                ]
              }
            }}
          />
        </div>

        <div className={theme.chartarea}>
          <Bar
            data={this.state.barChartdata}
            height={280}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              title: {
                display: true,
                text: "Wind Speed Chart",
                fontSize: 12,
              },
              scales: {
                xAxes: [{
                  ticks: {
                    maxRotation: 90,
                  }
                }],
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              }
            }}
          />
        </div>

        <div className={theme.chartarea}>
          <Line data={this.state.sunLineData}
            height={280}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              point: {
                radius: 0
              },
              tooltips: {
                mode: 'label'
              },
              hover: {
                mode: 'dataset'
              },
              scales: {
                xAxes: [
                  {
                    type: "time",
                    time: { unit: "day", displayFormats: { day: "YYYY-MM-DD" } },
                  },
                ]
              }
            }}
          />
        </div>

        <br></br>
        <br></br>

        <MapContainer
        center={this.state.currentCoordinate}
        zoom={9}
        scrollWheelZoom={true}
        style={{ marginTop:40, marginBottom:40, width: "60mm", height: "calc(60vh - 4rem)" }}>
        <ChangeView center={this.state.currentCoordinate} zoom={9} /> 
        <TileLayer
        url= "http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        attribution='Mingfei'
        />
        <Marker position={this.state.currentCoordinate}>
        <Popup>
          Vous êtes ici
        </Popup>
        </Marker>
        </MapContainer>
 
      </div>
      </ThemeProvider>
    )
  };


}
