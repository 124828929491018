import React, { useState, useEffect } from 'react';
import {Bar, Line, Doughnut} from 'react-chartjs-2';
import axios from 'axios';

import  {api_url} from './FetchMingfeiApi'
import Button from "@mui/material/Button";

import { useTheme } from '@mui/material/styles';

import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import useClasses from '../UseClass';
// https://www.youtube.com/watch?v=5-ptp9tRApM
// https://medium.com/@sapucaialuann/using-chartjs-and-react-hooks-to-create-a-line-chart-related-to-an-api-acef68f62d46#id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImUxOTdiZjJlODdiZDE5MDU1NzVmOWI2ZTVlYjQyNmVkYTVkNTc0ZTMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2MDgwMDY5NDQsImF1ZCI6IjIxNjI5NjAzNTgzNC1rMWs2cWUwNjBzMnRwMmEyamFtNGxqZGNtczAwc3R0Zy5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjEwNzk2NjgyMDI0MjY4ODg4MTgzOCIsImVtYWlsIjoibWF0dGhldy5tLmNoaWFuZ0BnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXpwIjoiMjE2Mjk2MDM1ODM0LWsxazZxZTA2MHMydHAyYTJqYW00bGpkY21zMDBzdHRnLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwibmFtZSI6Ik0gSklBTkciLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2k0NW9UREUwMTd4SEJNcFhzT2RETHJfbk1IRFdneDl1SHd5bDZJdWc9czk2LWMiLCJnaXZlbl9uYW1lIjoiTSIsImZhbWlseV9uYW1lIjoiSklBTkciLCJpYXQiOjE2MDgwMDcyNDQsImV4cCI6MTYwODAxMDg0NCwianRpIjoiZWU2MDAwNDlhNDk4Mjk2MDRkMDIyZjM3NzZmOTBiZmM0MjMwNGIwZSJ9.MDOdwBeaLWLe4ndmZ7fOjifODKfLcCYsLPFvwvrCXGdvyYvpuIq5hCiGqgku6dICLvOi-oQ5pCexaxSWr2fFMxL0GE-PfhF7adw5N3SCXn0EtzkE_U3ROfuEJAlhR7SEdolPG61lioirlzunqSIk2FaWDbtbO19X1gtffKUXac3e6IbaPewlFRZbgw3NDaKQQaWlC8p6peKrTgwqI3x88GlORVeVAtHki7WlBxW0yyZRpxmwgxG-iveu9Dg7N5CSHf70-Bc5mD0YtYi_-SwSwKQxf02MnHC6DjlTG9GE5TfE6WD1rXqzppG4O6HHnYEd3Ayfi0lpEsKpLWDLmYTLpA

const styles = theme => ({
  chartarea: {
    display: 'flex',
    position:"relative",
    marginTop: 25,
    minHeight: 280,
    maxHeight: 420,
  },

  formControl: {
    margin: 1, // theme.spacing(1),
    minWidth: 120,
    maxWidth: 600,
    display:'flex',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
});




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, personName, theme) {
  
  return {    
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export default function BarChart() {

const [ dataChart, setDataChart ] = useState ( {} );
const [loading, setLoading] = useState(true);
const [labelList, setLabelList]=useState([]);
const [suggestions, setSuggestions] = React.useState([]); 

const classes = useClasses(styles);
const theme = useTheme();


// const [origin, setOrigin] = React.useState([]);

const [selector1, setSelector1] = React.useState([]);
const [selector2, setSelector2] = React.useState([]);

const [groupBySum, setGroupBySum] = React.useState(null);
const [lineHistData, setLineHistData] = React.useState([]);
const [paraChart, setParaChart] = React.useState([]); 
const [chartSource, setChartSource] = React.useState([]); 
const [paraResult, setParaResult] = React.useState([]); 

const [sugChartdata, setSugChartdata] = React.useState([]); 

useEffect(() => {

const fetchData = async () => {
  if (loading===true && selector1.length===0){

  let myChartData=[];
  // let scores = [];
  // let dateOfCases = [];
  await axios.get(api_url)
    .then ( response => {
      response.data.forEach(({Brand, Description, Rating,createdAt,parameter}) => {
        myChartData.push({Brand, Description, Rating, createdAt,parameter})

      })
      
  });

  setChartSource(myChartData)

  // group by sum: acc[it.Brand.toUpperCase()]===Rating
  // const groupBySum = myChartData.reduce((acc, it) => {
  //   acc[it.Brand.toUpperCase()] = acc[it.Brand.toUpperCase()] + it.Rating || it.Rating;
  //   return acc;
  // }, {});
  let myChartDataFlat=[]
  var d1 = new Date();
  d1.setDate(d1.getDate()-60);
 
//-------------------------------------------------
  myChartData.forEach(element =>
    {if (element.parameter.length>0 && new Date(element.createdAt.substring(0, 10))>d1)
    {myChartDataFlat.push({
      "createdAt":element.createdAt.substring(0, 10),
      "Symbole": element.Brand,
      "sug":element.Description.substring(0, 5).replace("[", "").replace("]", ""),
      "FW_PE":element.parameter[0].FW_PE,
      "Gross_Margin":element.parameter[0].Gross_Margin,
      "Net_Profit":element.parameter[0].Net_Profit,
      "PEG_ratio":element.parameter[0].PEG_ratio,
      "Short_ratio":element.parameter[0].Short_ratio,
      "ROE":element.parameter[0].ROE,
      "Current_ratio":element.parameter[0].Current_ratio,
      "current_to_200":element.parameter[0].current_to_200,
      "count":1
    },)}});

    // setOrigin(myChartDataFlat)

    var helper = {};
    var result = myChartDataFlat.reduce(function(r, o) {
    // var key = o.year_month + '-' + o.category; // multiple group by
    var key = o.Symbole;

    if(!helper[key]) {
    helper[key] = Object.assign({}, o); // create a copy of o
    r.push(helper[key]);
    } else {
      helper[key].FW_PE += o.FW_PE;
      helper[key].Gross_Margin += o.Gross_Margin;
      helper[key].Net_Profit += o.Net_Profit;
      helper[key].PEG_ratio += o.PEG_ratio;
      helper[key].Short_ratio += o.Short_ratio;   
      helper[key].ROE += o.ROE;
      helper[key].Current_ratio += o.Current_ratio;
      helper[key].count += o.count;
      
    }
    return r;
    }, []);


    // console.log(result)
    setParaResult(result)

    let paraChartLable=[]
    let paraChartFwpe=[]
    let paraChartGm=[]
    let paraChartNp=[]
    let paraChartPeg=[]
    let paraChartShort=[]
    let paraChartRoe=[]
    let paraChartCurrent=[]

    result.forEach(element => 
    { paraChartLable.push(element.Symbole)
      paraChartFwpe.push(element.FW_PE/element.count)
      paraChartGm.push(element.Gross_Margin/element.count)
      paraChartNp.push(element.Net_Profit/element.count)
      paraChartPeg.push(element.PEG_ratio/element.count)
      paraChartShort.push(element.Short_ratio/element.count)
      paraChartRoe.push(element.ROE/element.count)
      paraChartCurrent.push(element.Current_ratio/element.count)
    });


    setParaChart({ 
      labels: paraChartLable,
      datasets: [{ 
        label: 'FW PE',
        backgroundColor: 'rgba(48, 102, 190,0.2)',
        borderColor: 'rgba(48, 102, 190,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(48, 102, 190,0.4)',
        hoverBorderColor: 'rgba(48, 102, 190,1)',
        data: paraChartFwpe
      },
      { 
        label: 'Gross Margin',
        backgroundColor: 'rgba(17, 157, 164,0.2)',
        borderColor: 'rgba(17, 157, 164,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(17, 157, 164,0.4)',
        hoverBorderColor: 'rgba(17, 157, 164,1)',
        data: paraChartGm
      },
      { 
        label: 'Net Margin',
        backgroundColor: 'rgba(109, 157, 197,0.2)',
        borderColor: 'rgba(109, 157, 197,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(109, 157, 197,0.4)',
        hoverBorderColor: 'rgba(109, 157, 197,1)',
        data: paraChartNp
      },
      { 
        label: 'PEG Ratio',
        backgroundColor: 'rgba(128, 222, 217,0.2)',
        borderColor: 'rgba(128, 222, 217,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(128, 222, 217,0.4)',
        hoverBorderColor: 'rgba(128, 222, 217,1)',
        data: paraChartPeg
      },
      { 
        label: 'Short Ratio',
        backgroundColor: 'rgba(174, 236, 239,0.2)',
        borderColor: 'rgba(174, 236, 239,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(174, 236, 239,0.4)',
        hoverBorderColor: 'rgba(174, 236, 239,1)',
        data: paraChartShort
      },
      { 
        label: 'ROE',
        backgroundColor: 'rgba(227, 102, 214,0.2)',
        borderColor: 'rgba(227, 102, 214,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(227, 102, 214,0.4)',
        hoverBorderColor: 'rgba(227, 102, 214,1)',
        data: paraChartRoe
      },
      { 
        label: 'Current',
        backgroundColor: 'rgba(19, 16, 163,0.2)',
        borderColor: 'rgba(19, 16, 163,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(19, 16, 163,0.4)',
        hoverBorderColor: 'rgba(19, 16, 163,1)',
        data: paraChartCurrent
      },

    ]
    });
//----------------buy/hold/sell---------------------------------


    var helperSug = {};
    var resultSug = myChartDataFlat.reduce(function(r, o) {
    // var key = o.year_month + '-' + o.category; // multiple group by
    var keySug = o.sug;

    if(!helperSug[keySug]) {
    helperSug[keySug] = Object.assign({}, o); // create a copy of o
    r.push(helperSug[keySug]);
    } else {
      helperSug[keySug].count += o.count;
    }
    return r;
    }, []);

    let sugChartLabel=[]
    let sugChartCount=[]

    resultSug.forEach(element => 
    { sugChartLabel.push(element.sug)
      sugChartCount.push(element.count)
    });

    setSuggestions(sugChartLabel)

// Dognut data
    setSugChartdata({
      labels: sugChartLabel,
      datasets: [
        {
          label: "Recommendation",
          backgroundColor: ['rgba(245, 240, 208,0.8)', 'rgba(244, 208, 245,0.8)', 'rgba(52, 245, 117,0.8)'  ], //hold sell buy
          hoverBackgroundColor: ['rgba(245, 240, 208,0.4)','rgba(244, 208, 245,0.4)', 'rgba(52, 245, 117,0.4)'],
          borderColor: ['rgba(245, 240, 208,1)','rgba(244, 208, 245,1)','rgba(52, 245, 117,1)'],
          borderWidth: 1,
          data: sugChartCount,
        },
      ],
    });

//-------------------------------------------------

  // group by Mean BAR CHART -->Avg Score Chart

  let filter_myChartData=[]
  myChartData.forEach(function (item, index) {
    if (new Date(item["createdAt"])>d1)
    // console.log(item);
    filter_myChartData.push(item)
  })
  

  let groupByMean = Object.entries(filter_myChartData
    .reduce((acc, x) => {
      let arr = acc[x.Brand] || [];
      // if (new Date(acc[x.createdAt])>d1)
      arr.push(x.Rating);
      acc[x.Brand] = arr;
      return acc;
    }, {}))
    .map((x)=> { 
       let sym = x[0];
       let Ratings = x[1];
       let sum = Ratings.reduce((a, b) => a + b, 0);
       let avg = (sum / Ratings.length) || 0; 
       return { "symbole": sym, "average_rating": avg };
    });
     

  //sort
  let groupByMean_sorted=groupByMean.sort((a,b) => (a.average_rating > b.average_rating) ? 1 : ((b.average_rating > a.average_rating) ? -1 : 0))


  setGroupBySum(groupByMean_sorted)

  
  let chartLable=[]
  let chartValue=[]
  groupByMean_sorted.forEach(element => 
    {chartLable.push(element.symbole)
    chartValue.push(element.average_rating)}
    );

  // let chartLable=Object.entries(groupByMean).sort((a,b) => a[1]-b[1]).map(el=>el[0]);
  // let chartValue=Object.entries(groupByMean).sort((a,b) => a[1]-b[1]).map(el=>el[1]);
  setLabelList(chartLable);

  setDataChart({ 
    labels: chartLable,
    datasets: [{ 
      label: 'Avg Stock Watcher Score',
      backgroundColor: 'rgba(12, 200, 109,0.2)',
      borderColor: 'rgba(12, 200, 109,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(12, 200, 109,0.4)',
      hoverBorderColor: 'rgba(12, 200, 109,1)',
      data: chartValue 
    }]
  });



  let datelist = [];
  let countDaily=[];
 // group by sum: acc[it.Brand.toUpperCase()]===Rating
  const groupByDailyCount = myChartData.reduce((acc, it) => {
    acc[it.createdAt.substring(0, 10)] = acc[it.createdAt.substring(0, 10)] + 1 || 1;
    return acc;
  }, {});

  datelist=Object.keys(groupByDailyCount)
  countDaily=Object.values(groupByDailyCount)
//  console.log("groupByDailyCount: ",groupByDailyCount)

  setLineHistData({
    labels: datelist, // lineChartDate, histDate.reverse(),
    datasets: [
      {
        label: "Daily Query",
        data: countDaily, //.reverse(),
        fill: true,
        borderColor: "#338796",
      }
    ],
  }); 
  setLoading(false);
} 
else {}
}

fetchData()

}


);

const handleReset = () => {
    let paraChartLable=[]
    let paraChartFwpe=[]
    let paraChartGm=[]
    let paraChartNp=[]
    let paraChartPeg=[]
    let paraChartShort=[]
    let paraChartRoe=[]
    let paraChartCurrent=[]

    paraResult.forEach(element => 
    { paraChartLable.push(element.Symbole)
      paraChartFwpe.push(element.FW_PE/element.count)
      paraChartGm.push(element.Gross_Margin/element.count)
      paraChartNp.push(element.Net_Profit/element.count)
      paraChartPeg.push(element.PEG_ratio/element.count)
      paraChartShort.push(element.Short_ratio/element.count)
      paraChartRoe.push(element.ROE/element.count)
      paraChartCurrent.push(element.Current_ratio/element.count)

    });


    setParaChart({ 
      labels: paraChartLable,
      datasets: [{ 
        label: 'FW PE',
        backgroundColor: 'rgba(48, 102, 190,0.2)',
        borderColor: 'rgba(48, 102, 190,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(48, 102, 190,0.4)',
        hoverBorderColor: 'rgba(48, 102, 190,1)',
        data: paraChartFwpe
      },
      { 
        label: 'Gross Margin',
        backgroundColor: 'rgba(17, 157, 164,0.2)',
        borderColor: 'rgba(17, 157, 164,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(17, 157, 164,0.4)',
        hoverBorderColor: 'rgba(17, 157, 164,1)',
        data: paraChartGm
      },
      { 
        label: 'Net Margin',
        backgroundColor: 'rgba(109, 157, 197,0.2)',
        borderColor: 'rgba(109, 157, 197,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(109, 157, 197,0.4)',
        hoverBorderColor: 'rgba(109, 157, 197,1)',
        data: paraChartNp
      },
      { 
        label: 'PEG Ratio',
        backgroundColor: 'rgba(128, 222, 217,0.2)',
        borderColor: 'rgba(128, 222, 217,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(128, 222, 217,0.4)',
        hoverBorderColor: 'rgba(128, 222, 217,1)',
        data: paraChartPeg
      },
      { 
        label: 'Short Ratio',
        backgroundColor: 'rgba(174, 236, 239,0.2)',
        borderColor: 'rgba(174, 236, 239,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(174, 236, 239,0.4)',
        hoverBorderColor: 'rgba(174, 236, 239,1)',
        data: paraChartShort
      },
      { 
        label: 'ROE',
        backgroundColor: 'rgba(227, 102, 214,0.2)',
        borderColor: 'rgba(227, 102, 214,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(227, 102, 214,0.4)',
        hoverBorderColor: 'rgba(227, 102, 214,1)',
        data: paraChartRoe
      },
      { 
        label: 'Current',
        backgroundColor: 'rgba(19, 16, 163,0.2)',
        borderColor: 'rgba(19, 16, 163,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(19, 16, 163,0.4)',
        hoverBorderColor: 'rgba(19, 16, 163,1)',
        data: paraChartCurrent
      },

    ]
    });

  let chartLable=[];
  let chartValue=[];
  groupBySum.forEach(element => 
    {chartLable.push(element.symbole)
    chartValue.push(element.average_rating)}
    );


  setSelector1([]);
  setSelector2([]);
  setDataChart({ 
    labels: chartLable,
    datasets: [{ 
      label: 'Avg Stock Watcher Score',
      backgroundColor: 'rgba(12, 200, 109,0.2)',
      borderColor: 'rgba(12, 200, 109,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(12, 200, 109,0.4)',
      hoverBorderColor: 'rgba(12, 200, 109,1)',
      data: chartValue 
    }]
  });


  let datelist = [];
  let countDaily=[];
 // group by sum: acc[it.Brand.toUpperCase()]===Rating
  const groupByDailyCount = chartSource.reduce((acc, it) => {
    acc[it.createdAt.substring(0, 10)] = acc[it.createdAt.substring(0, 10)] + 1 || 1;
    return acc;
  }, {});

  datelist=Object.keys(groupByDailyCount)
  countDaily=Object.values(groupByDailyCount)
//  console.log("groupByDailyCount: ",groupByDailyCount)

  setLineHistData({
    labels: datelist, // lineChartDate, histDate.reverse(),
    datasets: [
      {
        label: "Daily Query",
        data: countDaily, //.reverse(),
        fill: true,
        borderColor: "#338796",
      }
    ],
  }); 

};

const handleChangeSelector = async(event) => {
  // setPersonName(event.target.value);
  setSelector1(event.target.value)
  
  // test
  let myChartDataFlat=[];

  let chartLable=[];
  let chartValue=[];
  let arr=event.target.value
  let mySelector2=(selector2.length===0) ? suggestions:selector2;

  //---------parameter charts-------------------
  chartSource.forEach(element =>
    {if (element.parameter.length>0 && arr.indexOf(element.Brand) > -1 
      && mySelector2.indexOf(element.Description.substring(0, 5).replace("[", "").replace("]", "")) > -1)
    {myChartDataFlat.push({
      "createdAt":element.createdAt.substring(0, 10),
      "Symbole": element.Brand,
      "sug":element.Description.substring(0, 5).replace("[", "").replace("]", ""),
      "Rating":element.Rating,
      "FW_PE":element.parameter[0].FW_PE,
      "Gross_Margin":element.parameter[0].Gross_Margin,
      "Net_Profit":element.parameter[0].Net_Profit,
      "PEG_ratio":element.parameter[0].PEG_ratio,
      "Short_ratio":element.parameter[0].Short_ratio,
      "ROE":element.parameter[0].ROE,
      "Current_ratio":element.parameter[0].Current_ratio,
      "current_to_200":element.parameter[0].current_to_200,
      "count":1
    },)}});

    var helper = {};
    var result = myChartDataFlat.reduce(function(r, o) {
    // var key = o.year_month + '-' + o.category; // multiple group by
    var key = o.Symbole;

    if(!helper[key]) {
    helper[key] = Object.assign({}, o); // create a copy of o
    r.push(helper[key]);
    } else {
      helper[key].FW_PE += o.FW_PE;
      helper[key].Gross_Margin += o.Gross_Margin;
      helper[key].Net_Profit += o.Net_Profit;
      helper[key].PEG_ratio += o.PEG_ratio;
      helper[key].Short_ratio += o.Short_ratio;   
      helper[key].ROE += o.ROE;
      helper[key].Current_ratio += o.Current_ratio;
      helper[key].count += o.count;
      
    }
    return r;
    }, []);

    // console.log(result)

    let paraChartLable=[]
    let paraChartFwpe=[]
    let paraChartGm=[]
    let paraChartNp=[]
    let paraChartPeg=[]
    let paraChartShort=[]
    let paraChartRoe=[]
    let paraChartCurrent=[]

    result.forEach(element => 
    { paraChartLable.push(element.Symbole)
      paraChartFwpe.push(element.FW_PE/element.count)
      paraChartGm.push(element.Gross_Margin/element.count)
      paraChartNp.push(element.Net_Profit/element.count)
      paraChartPeg.push(element.PEG_ratio/element.count)
      paraChartShort.push(element.Short_ratio/element.count)
      paraChartRoe.push(element.ROE/element.count)
      paraChartCurrent.push(element.Current_ratio/element.count)

    });


    setParaChart({ 
      labels: paraChartLable,
      datasets: [{ 
        label: 'FW PE',
        backgroundColor: 'rgba(48, 102, 190,0.2)',
        borderColor: 'rgba(48, 102, 190,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(48, 102, 190,0.4)',
        hoverBorderColor: 'rgba(48, 102, 190,1)',
        data: paraChartFwpe
      },
      { 
        label: 'Gross Margin',
        backgroundColor: 'rgba(17, 157, 164,0.2)',
        borderColor: 'rgba(17, 157, 164,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(17, 157, 164,0.4)',
        hoverBorderColor: 'rgba(17, 157, 164,1)',
        data: paraChartGm
      },
      { 
        label: 'Net Margin',
        backgroundColor: 'rgba(109, 157, 197,0.2)',
        borderColor: 'rgba(109, 157, 197,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(109, 157, 197,0.4)',
        hoverBorderColor: 'rgba(109, 157, 197,1)',
        data: paraChartNp
      },
      { 
        label: 'PEG Ratio',
        backgroundColor: 'rgba(128, 222, 217,0.2)',
        borderColor: 'rgba(128, 222, 217,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(128, 222, 217,0.4)',
        hoverBorderColor: 'rgba(128, 222, 217,1)',
        data: paraChartPeg
      },
      { 
        label: 'Short Ratio',
        backgroundColor: 'rgba(174, 236, 239,0.2)',
        borderColor: 'rgba(174, 236, 239,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(174, 236, 239,0.4)',
        hoverBorderColor: 'rgba(174, 236, 239,1)',
        data: paraChartShort
      },
      { 
        label: 'ROE',
        backgroundColor: 'rgba(227, 102, 214,0.2)',
        borderColor: 'rgba(227, 102, 214,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(227, 102, 214,0.4)',
        hoverBorderColor: 'rgba(227, 102, 214,1)',
        data: paraChartRoe
      },
      { 
        label: 'Current',
        backgroundColor: 'rgba(19, 16, 163,0.2)',
        borderColor: 'rgba(19, 16, 163,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(19, 16, 163,0.4)',
        hoverBorderColor: 'rgba(19, 16, 163,1)',
        data: paraChartCurrent
      },

    ]
    }); 
  
  //-------------------------------------------
  let groupByMean = Object.entries(myChartDataFlat
    .reduce((acc, x) => {
      let arr = acc[x.Symbole] || [];
      arr.push(x.Rating);
      acc[x.Symbole] = arr;
      return acc;
    }, {}))
    .map((x)=> { 
       let sym = x[0];
       let Ratings = x[1];
       let sum = Ratings.reduce((a, b) => a + b, 0);
       let avg = (sum / Ratings.length) || 0; 
       return { "symbole": sym, "average_rating": avg };
    });
     
  //sort
  let groupByMean_sorted=groupByMean.sort((a,b) => (a.average_rating > b.average_rating) ? 1 : ((b.average_rating > a.average_rating) ? -1 : 0))

  groupByMean_sorted.forEach(function(element)
  {if (arr.indexOf(element.symbole) > -1)
    {
    chartLable.push(element.symbole)
    chartValue.push(element.average_rating)
    } 
  });


  setDataChart({ 
    labels: chartLable,
    datasets: [{ 
      label: 'avg score',
      backgroundColor: 'rgba(12, 200, 109,0.2)',
      borderColor: 'rgba(12, 200, 109,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(12, 200, 109,0.4)',
      hoverBorderColor: 'rgba(12, 200, 109,1)',
      data: chartValue
    }]
  });
  
  // -----------------------
  let datelist = [];
  let countDaily=[];
 // group by sum: acc[it.Brand.toUpperCase()]===Rating
  const groupByDailyCount = myChartDataFlat.reduce((acc, it) => {
    acc[it.createdAt.substring(0, 10)] = acc[it.createdAt.substring(0, 10)] + 1 || 1;
    return acc;
  }, {});

  datelist=Object.keys(groupByDailyCount)
  countDaily=Object.values(groupByDailyCount)
//  console.log("groupByDailyCount: ",groupByDailyCount)

  setLineHistData({
    labels: datelist, // lineChartDate, histDate.reverse(),
    datasets: [
      {
        label: "Daily Query",
        data: countDaily, //.reverse(),
        fill: true,
        borderColor: "#338796",
      }
    ],
  }); 
};


const handleSugSelector = async(event) => {
  // setSugselected(event.target.value);
  setSelector2(event.target.value);

  let myChartDataFlat=[];
  let chartLable=[];
  let chartValue=[];
  let arrSug=event.target.value
  let mySelector1=(selector1.length===0) ? labelList:selector1;

  //---------parameter charts-------------------
  chartSource.forEach(element =>
    {if (element.parameter.length>0 && mySelector1.indexOf(element.Brand) > -1 
      && arrSug.indexOf(element.Description.substring(0, 5).replace("[", "").replace("]", "")) > -1)
    {myChartDataFlat.push({
      "createdAt":element.createdAt.substring(0, 10),
      "Symbole": element.Brand,
      "sug":element.Description.substring(0, 5).replace("[", "").replace("]", ""),
      "Rating":element.Rating,
      "FW_PE":element.parameter[0].FW_PE,
      "Gross_Margin":element.parameter[0].Gross_Margin,
      "Net_Profit":element.parameter[0].Net_Profit,
      "PEG_ratio":element.parameter[0].PEG_ratio,
      "Short_ratio":element.parameter[0].Short_ratio,
      "ROE":element.parameter[0].ROE,
      "Current_ratio":element.parameter[0].Current_ratio,
      "current_to_200":element.parameter[0].current_to_200,
      "count":1
    },)}});

    var helper = {};
    var result = myChartDataFlat.reduce(function(r, o) {
    // var key = o.year_month + '-' + o.category; // multiple group by
    var key = o.Symbole;

    if(!helper[key]) {
    helper[key] = Object.assign({}, o); // create a copy of o
    r.push(helper[key]);
    } else {
      helper[key].FW_PE += o.FW_PE;
      helper[key].Gross_Margin += o.Gross_Margin;
      helper[key].Net_Profit += o.Net_Profit;
      helper[key].PEG_ratio += o.PEG_ratio;
      helper[key].Short_ratio += o.Short_ratio;   
      helper[key].ROE += o.ROE;
      helper[key].Current_ratio += o.Current_ratio;
      helper[key].count += o.count;
      
    }
    return r;
    }, []);




    let paraChartLable=[]
    let paraChartFwpe=[]
    let paraChartGm=[]
    let paraChartNp=[]
    let paraChartPeg=[]
    let paraChartShort=[]
    let paraChartRoe=[]
    let paraChartCurrent=[]

    result.forEach(element => 
    { paraChartLable.push(element.Symbole)
      paraChartFwpe.push(element.FW_PE/element.count)
      paraChartGm.push(element.Gross_Margin/element.count)
      paraChartNp.push(element.Net_Profit/element.count)
      paraChartPeg.push(element.PEG_ratio/element.count)
      paraChartShort.push(element.Short_ratio/element.count)
      paraChartRoe.push(element.ROE/element.count)
      paraChartCurrent.push(element.Current_ratio/element.count)

    });


    setParaChart({ 
      labels: paraChartLable,
      datasets: [{ 
        label: 'FW PE',
        backgroundColor: 'rgba(48, 102, 190,0.2)',
        borderColor: 'rgba(48, 102, 190,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(48, 102, 190,0.4)',
        hoverBorderColor: 'rgba(48, 102, 190,1)',
        data: paraChartFwpe
      },
      { 
        label: 'Gross Margin',
        backgroundColor: 'rgba(17, 157, 164,0.2)',
        borderColor: 'rgba(17, 157, 164,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(17, 157, 164,0.4)',
        hoverBorderColor: 'rgba(17, 157, 164,1)',
        data: paraChartGm
      },
      { 
        label: 'Net Margin',
        backgroundColor: 'rgba(109, 157, 197,0.2)',
        borderColor: 'rgba(109, 157, 197,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(109, 157, 197,0.4)',
        hoverBorderColor: 'rgba(109, 157, 197,1)',
        data: paraChartNp
      },
      { 
        label: 'PEG Ratio',
        backgroundColor: 'rgba(128, 222, 217,0.2)',
        borderColor: 'rgba(128, 222, 217,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(128, 222, 217,0.4)',
        hoverBorderColor: 'rgba(128, 222, 217,1)',
        data: paraChartPeg
      },
      { 
        label: 'Short Ratio',
        backgroundColor: 'rgba(174, 236, 239,0.2)',
        borderColor: 'rgba(174, 236, 239,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(174, 236, 239,0.4)',
        hoverBorderColor: 'rgba(174, 236, 239,1)',
        data: paraChartShort
      },
      { 
        label: 'ROE',
        backgroundColor: 'rgba(227, 102, 214,0.2)',
        borderColor: 'rgba(227, 102, 214,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(227, 102, 214,0.4)',
        hoverBorderColor: 'rgba(227, 102, 214,1)',
        data: paraChartRoe
      },
      { 
        label: 'Current',
        backgroundColor: 'rgba(19, 16, 163,0.2)',
        borderColor: 'rgba(19, 16, 163,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(19, 16, 163,0.4)',
        hoverBorderColor: 'rgba(19, 16, 163,1)',
        data: paraChartCurrent
      },

    ]
    }); 
  
  //-------------------------------------------
  let groupByMean = Object.entries(myChartDataFlat
    .reduce((acc, x) => {
      let arr = acc[x.Symbole] || [];
      arr.push(x.Rating);
      acc[x.Symbole] = arr;
      return acc;
    }, {}))
    .map((x)=> { 
       let sym = x[0];
       let Ratings = x[1];
       let sum = Ratings.reduce((a, b) => a + b, 0);
       let avg = (sum / Ratings.length) || 0; 
       return { "symbole": sym, "average_rating": avg };
    });
     

  //sort
  let groupByMean_sorted=groupByMean.sort((a,b) => (a.average_rating > b.average_rating) ? 1 : ((b.average_rating > a.average_rating) ? -1 : 0))

  // console.log("groupByMean_sorted :",groupByMean_sorted)
  groupByMean_sorted.forEach(function(element)
  {if (mySelector1.indexOf(element.symbole) > -1)
    {
    chartLable.push(element.symbole)
    chartValue.push(element.average_rating)
    } 
  });


  setDataChart({ 
    labels: chartLable,
    datasets: [{ 
      label: 'avg score',
      backgroundColor: 'rgba(12, 200, 109,0.2)',
      borderColor: 'rgba(12, 200, 109,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(12, 200, 109,0.4)',
      hoverBorderColor: 'rgba(12, 200, 109,1)',
      data: chartValue
    }]
  });

  // -----------------------
  let datelist = [];
  let countDaily=[];
 // group by sum: acc[it.Brand.toUpperCase()]===Rating
  const groupByDailyCount = myChartDataFlat.reduce((acc, it) => {
    acc[it.createdAt.substring(0, 10)] = acc[it.createdAt.substring(0, 10)] + 1 || 1;
    return acc;
  }, {});

  datelist=Object.keys(groupByDailyCount)
  countDaily=Object.values(groupByDailyCount)
//  console.log("groupByDailyCount: ",groupByDailyCount)

  setLineHistData({
    labels: datelist, // lineChartDate, histDate.reverse(),
    datasets: [
      {
        label: "Daily Query",
        data: countDaily, //.reverse(),
        fill: true,
        borderColor: "#338796",
      }
    ],
  }); 


};
    return (
      <div>      
        <h5>History</h5>
        <div>
          <FormControl className={classes.formControl}> 
          <InputLabel id="demo-mutiple-chip-label">Symbole</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={selector1}
            onChange={handleChangeSelector}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {labelList.map((name) => (
              <MenuItem key={name} value={name} style={getStyles(name, selector1, theme)}>
                {name}
              </MenuItem>
            ))}
          </Select>         
          </FormControl>

          <FormControl className={classes.formControl}> 
          <InputLabel id="sug-selector">Suggestions</InputLabel>
          <Select
            labelId="sug-selector-label"
            id="sug-selector-chip"
            multiple
            value={selector2}
            onChange={handleSugSelector}
            input={<Input id="select-sug-selector-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {suggestions.map((name) => (
              <MenuItem key={name} value={name} style={getStyles(name, selector2, theme)}>
                {name}
              </MenuItem>
            ))}
          </Select>         
          </FormControl>


          <Button
          style={{ margin: 9}}
          onClick={handleReset}
          variant="contained"
          color="primary"
          >Reset
          </Button></div>

          <div className={classes.chartarea}>         
          <Bar
            data={dataChart}
            width={80}
            height={100}
            options={{
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: "Avg Scores (60 Days)",
              fontSize: 12,
            },
            scales: {
              xAxes: [{
                ticks: {
                  maxRotation: 90,
                }
              }],
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            }
            }}
          />  
          </div>

          <div className={classes.chartarea}>         
          <Bar
            data={paraChart}
            width={80}
            height={100}
            options={{
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: "Fundamentals (60 Days)",
              fontSize: 12,
            },
            scales: {
              xAxes: [{
                ticks: {
                  maxRotation: 90,
                }
              }],
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            }
            }}
          />  
          </div>


          <div className={classes.chartarea}>
          <Doughnut
            data={sugChartdata}
            height={280}
            options={{
              maintainAspectRatio: false,
              title: {
                display: true,
                text: "Recommandation",
                fontSize: 12,
              },
              legend: {
                display: true,
                // position: "right",
              },
            }}
          />
          </div>

          <div className={classes.chartarea}>
          <Line data={lineHistData}
          height={280}
          options={{
          responsive: true,
          maintainAspectRatio: false,
          point:{
          radius: 0
          },
          tooltips: {
          mode: 'label'
          },
          hover: {
          mode: 'dataset'
          },
          scales:{
            xAxes: [
            {
            type: "time",
            time: { unit: "day", displayFormats: { day: "YYYY-MM-DD" } },
            },
            ]}
          }}
          />
          </div>



      </div>
    );
}

